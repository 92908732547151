<template>
  <metadata-table class="zupu-details">
    <template v-slot:rows>
      <details-item label="Surname">
        <link-surname-page-family :clans="zupu.clans" :show-family="false"></link-surname-page-family>
      </details-item>
      <details-item
        label="Ancestral Place"
        v-if="zupu.ancestral_places && zupu.ancestral_places.length"
        v-prerender-if="'ancestral_places'"
      >
        <div v-for="place in zupu.ancestral_places" :key="place.id">
          <link-place-page :place="place" name-field="display_text">{{ place.pinyin }}</link-place-page>
        </div>
      </details-item>
      <details-item label="Title"> {{ zupu.title_pinyin || '' }} {{ zupu.title_original || '' }} </details-item>
      <template v-for="provider in zupu.providers || []">
        <details-item label="Library" :key="'library' + provider.name">
          <div v-if="provider && provider.parent && provider.name !== provider.parent.name">
            {{ provider.parent.name }}
          </div>
          <div v-else>{{ provider.name }}</div>
        </details-item>
        <details-item
          v-if="provider && provider.parent && provider.name !== provider.parent.name"
          :key="'library-branch' + provider.name"
          label="Library Branch"
        >
          <div>{{ provider.name }} {{ provider.name_ch }}</div>
        </details-item>
      </template>

      <details-item v-if="zupu.clan_hall_name" label="Clan Hall">
        <div>{{ zupu.clan_hall_name }}</div>
      </details-item>
      <details-item v-if="zupu.author" label="Author">
        <div>{{ zupu.author }}</div>
      </details-item>
      <details-item label="Publication Place">
        <router-link
          v-if="zupu.publication_place"
          v-prerender-if="'publication_place'"
          :to="migrationDestinationRoute"
          >{{ zupu.publication_place.display_text }}</router-link
        >
      </details-item>
      <details-item label="Date Published">
        <div v-if="zupu.publication_year">
          {{ zupu.publication_year
          }}<span v-if="zupu.publication_year_latest">- {{ zupu.publication_year_latest }}</span>
        </div>
      </details-item>
      <details-item v-if="zupu.call_number" label="Call Number">
        <div>{{ zupu.call_number }}</div>
      </details-item>
      <details-item v-if="zupu.has_images" label="Pages">
        {{ zupu.images_count }}
      </details-item>
      <details-item label="Source" v-if="userIsStaffState">
        <div v-if="zupu.source">{{ zupu.source }}</div>
      </details-item>
    </template>
  </metadata-table>
</template>

<script>
import MetadataTable from '@common/elements/layouts/MetadataTable';
import detailsItem from '@common/elements/layouts/detailsItem';
import LinkPlacePage from '@common/elements/links/linkPlacePage';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {getMigrationPlaceDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

export default {
  props: {
    zupu: Object,
  },
  computed: {
    ...mapGetters(['userIsStaffState']),
    migrationDestinationRoute() {
      if (!this.zupu.publication_place) {
        return {};
      }
      return getMigrationPlaceDetailRoute(this.zupu.publication_place.id, this.zupu.publication_place.pinyin);
    },
  },
  components: {detailsItem, LinkPlacePage, linkSurnamePageFamily, MetadataTable},
  name: 'ZupuDetails',
};
</script>

<style scoped lang="scss"></style>
