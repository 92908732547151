<template>
  <div class="family-tree-profile-page" v-if="pageLoading">
    <div class="readable_content">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
  </div>
  <div class="family-tree-profile-page" v-else-if="!familyTreePersonState">
    <family-tree-no-access></family-tree-no-access>
  </div>
  <div class="family-tree-profile-page" v-else>
    <drag-drop-prompt v-if="isWriteAllowed" @drop="handleFilesDrop"></drag-drop-prompt>

    <div class="readable_content mobile_full_width">
      <div class="breadcrumb-link">
        <router-link :to="backRouteNoShift"><chevron-left-icon :size="16" />Back to tree</router-link>
      </div>
      <stand-out-block class="light heading-block">
        <div class="person-data-container">
          <div class="person-data">
            <div class="person-picture">
              <img v-if="profilePicture.attachment" :src="profilePicture.attachment" class="profile-picture" />
              <person-icon-colored v-else :gender="familyTreePersonState.gender"></person-icon-colored>
            </div>
            <div>
              <div class="name-container">
                <div>
                  <h5 v-if="familyTreePersonPrimaryFullnameEnState">{{ familyTreePersonPrimaryFullnameEnState }}</h5>
                  <h5 v-if="familyTreePersonPrimaryFullnameCnState">{{ familyTreePersonPrimaryFullnameCnState }}</h5>
                  <h5 v-if="!familyTreePersonPrimaryFullnameEnState && !familyTreePersonPrimaryFullnameCnState">
                    {{ unknownName }}
                  </h5>
                </div>
                <div v-if="birthOrder" class="birth-order">
                  <mcr-wiki-glossary-link :is-help-text="true" naturalId="sibling-order" :text="`#${birthOrder}`" />
                </div>
              </div>
              <div class="living-status">{{ livingStatus }}</div>
              <div class="upload-profile-picture" v-if="familyTreePersonState.is_write_allowed">
                <a @click="uploadPictureClick" :class="{inactive: profilePictureUpdateLoading}"
                  ><camera-icon :size="16" />Upload profile image</a
                >
                <bounce-loading v-if="profilePictureUpdateLoading" class="dark"></bounce-loading>
              </div>
              <input type="file" class="picture-input" ref="pictureinput" @change="pictureSelectedHandler" />
            </div>
          </div>
          <div class="controls">
            <mcr-button-router-link class="white small view-tree" :to="personInFTRoute"
              ><tree-icon class="" :size="20" />View in Tree</mcr-button-router-link
            >
            <context-menu-popover
              :sections="visibleContextMenuSections"
              :can-add-parent="!personHasBothParents"
              :search-by-profile-enabled="!!searchByProfileRoute"
              full-edit-label="Edit"
              @click-full-profile-edit="activateEditMode"
              @click-search-by-profile="searchByProfileClick"
              @click-add-parent="addParentClick"
              @click-add-spouse="addSpouseClick"
              @click-add-sibling="addSiblingClick"
              @click-add-child="addChildClick"
              @click-view-tree="clickViewTree"
              @click-view-lineage="clickViewLineage"
              @click-delete="deletePerson"
            ></context-menu-popover>
          </div>
        </div>
      </stand-out-block>

      <tabs-bar class="main-tabs-bar" ref="tabs" :start-tab="activeTab" @activate-tab="onActivateTab">
        <tabs-block-item :name="tabNamesById[TAB_DETAILS]" icon="profile-icon" :identifier="TAB_DETAILS">
          <tab-details ref="detailsTab"></tab-details>
        </tabs-block-item>
        <tabs-block-item
          :name="tabNamesById[TAB_RELATIONSHIPS]"
          icon="relationship-icon"
          :identifier="TAB_RELATIONSHIPS"
        >
          <tab-relationships ref="relationshipsTab"></tab-relationships>
        </tabs-block-item>
        <tabs-block-item :name="tabNamesById[TAB_LIBRARY]" icon="assets-icon" :identifier="TAB_LIBRARY">
          <tab-library ref="libraryTab"></tab-library>
        </tabs-block-item>
        <tabs-block-item
          :name="tabNamesById[TAB_HINTS]"
          icon="lightbulb-icon"
          :identifier="TAB_HINTS"
          :review-count="hintsReviewCount"
          v-if="showHintsTab"
        >
          <tab-hints @go-to-edit="activateEditMode" @go-to-relatives="activateRelativesTab"></tab-hints>
        </tabs-block-item>
      </tabs-bar>

      <stand-out-block v-if="showNoAccessNotification" class="text-md no-access-notification">
        <div class="icon-container"><lock-icon :size="28"></lock-icon></div>
        You don’t currently have access to this page. Please contact the family tree owner to request Editor rights.
      </stand-out-block>
    </div>
  </div>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import McrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import BounceLoading from '@common/elements/loading-indicators/bounceLoading';
import TabsBar from '@common/elements/tabs/TabsBar';
import TabsBlockItem from '@common/elements/tabs/TabsBlockItem';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import consts from '@common/utils/consts';
import {getLifetimeYears} from '@common/utils/utils';
import {getSearchByProfileRoute} from '@common/utils/utils.routes';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import CameraIcon from 'vue-material-design-icons/CameraOutline';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft';
import LockIcon from 'vue-material-design-icons/LockOutline';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import {mapGetters} from 'vuex';

import PersonIconColored from '@/base/icons/person/PersonIconColored';

import ContextMenuPopover from '@/components/common/tree/ContextMenuPopover';
import ExtractTextInterstitial from '@/components/common/tree/ExtractTextInterstitial.vue';
import FamilyTreeNoAccess from '@/components/common/tree/familyTreeNoAccess';
import PersonProfilePictureCrop from '@/components/common/tree/personProfilePictureCrop';

import {
  CARD_FULL_PROFILE_HELPER_KEY,
  CHILD_RELATION_TYPE,
  CONTEXT_MENU_SECTIONS,
  PARENT_RELATION_TYPE,
  SIBLING_RELATION_TYPE,
  SPOUSE_RELATION_TYPE,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import personDeleteConfirmModalContent from '@/components/modules/familyTree/tree/modals/personDeleteConfirmModalContent';
import personDeleteFailedModalContent from '@/components/modules/familyTree/tree/modals/personDeleteFailedModalContent';
import {openAddPersonModal} from '@/components/modules/familyTree/tree/services.modals';

import TabDetails from './TabDetails';
import TabHints from './TabHints';
import TabLibrary from './TabLibrary';
import TabRelationships from './TabRelationships';

const TAB_DETAILS = 'details';
const TAB_RELATIONSHIPS = 'relationships';
const TAB_LIBRARY = 'library';
const TAB_HINTS = 'hints';

const DEFAULT_TAB = TAB_DETAILS;

export default {
  metaInfo() {
    if (!this.familyTreePersonState) {
      return;
    }
    const name =
      this.familyTreePersonPrimaryFullnameEnState || this.familyTreePersonPrimaryFullnameCnState || this.unknownName;
    const tab = this.tabNamesById[this.activeTab];
    const treeName = this.familyTreeNameState || 'Family Tree';
    const pageName = [name, tab, treeName].join(' – ');
    return {
      title: pageName,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.activeTab === TAB_DETAILS &&
      this.$refs.detailsTab &&
      this.$refs.detailsTab.editMode &&
      this.$refs.detailsTab.$refs.editForm &&
      this.$refs.detailsTab.$refs.editForm.$refs.form.unsavedFields.length
    ) {
      return confirm(' Are you sure you want to leave? Changes you made will not be saved.') ? next() : null;
    }
    if (this.activeTab === TAB_DETAILS && this.$refs.tabs) {
      // call to set showEditButtonTooltip to false; to hide tooltip on route change
      this.$refs.tabs.fullDeactivate();
    }
    next();
  },
  activated() {
    if (this.$route.query.tab === TAB_HINTS && !this.$store.getters.userIsLoggedInState) {
      this.showLoading = true;
      this.$router.push({name: 'login', query: {redirect: this.$route.fullPath}});
      return;
    }
    this.$store.commit('setShowCardFullProfileTooltipState', false);
    this.$store.commit('addReviewedItemState', CARD_FULL_PROFILE_HELPER_KEY);
    this.trackTabOpened(this.$route.params.personId, this.$route.query.tab || DEFAULT_TAB);
    window.onbeforeunload = () => {
      if (
        this.activeTab === TAB_DETAILS &&
        this.$refs.detailsTab.editMode &&
        this.$refs.detailsTab.$refs.editForm.$refs.form.unsavedFields.length
      ) {
        return 'Are you sure you want to leave?';
      }
    };
    const prevRoute = this.$store.getters.previousRouteState;
    this.backRoute =
      prevRoute && this.prevRoutes.includes(prevRoute.name)
        ? prevRoute
        : this.backRoute.name
        ? this.backRoute
        : this.defaultBackRoute;
    if (isEmpty(this.$store.getters.familyTreePersonOptionsState)) {
      this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
    }
    const isMobile = this.$store.getters.windowWidthState <= this.$breakpoints.mobile;
    const needScroll = isMobile && this.$route.query.tab && this.$route.query.tab !== TAB_DETAILS;
    if (!this.familyTreePersonState || this.$route.params.personId != this.familyTreePersonState.object_id) {
      this.forceActivateTab(this.$route.query.tab);
      this.init(needScroll);
      return;
    }
    if (this.$route.query.tab !== this.activeTab) {
      this.forceActivateTab(this.$route.query.tab);
    }
    if (this.$route.query.edit) {
      this.activateEditMode(this.$route.query.focus);
      return;
    }
    if (needScroll) {
      this.scrollToContent();
    }
  },
  deactivated() {
    window.onbeforeunload = () => {};
  },
  data() {
    return {
      prevRoutes: ['familytree-details', 'familytree-lineage'],
      backRoute: {},
      defaultBackRoute: {name: 'familytree-details', params: {id: this.$route.params.id}},
      TAB_DETAILS,
      TAB_RELATIONSHIPS,
      TAB_LIBRARY,
      TAB_HINTS,
      activeTab: this.$route.query.tab || DEFAULT_TAB,
      maxFileSizeMB: consts.maxFileSizeMB,
      maxFileSize: consts.maxFileSizeMB * 1024 * 1024,
      allowedFileExtensions: consts.imageExtensions,
      profilePictureUpdateLoading: false,
      tabNamesById: {
        [TAB_DETAILS]: 'Details',
        [TAB_RELATIONSHIPS]: 'Family',
        [TAB_LIBRARY]: 'Photos',
        [TAB_HINTS]: 'Records',
      },
      showLoading: false,
    };
  },
  watch: {
    $route: {
      handler: function (toRoute, fromRoute) {
        const routeName = 'familytree-profile-details';
        const isProfileRoute = toRoute.name === routeName && fromRoute.name === routeName;
        const personChanged = toRoute.params.personId != fromRoute.params.personId;
        const tabChanged = toRoute.query.tab !== fromRoute.query.tab;
        if (isProfileRoute && (personChanged || tabChanged)) {
          this.trackTabOpened(toRoute.params.personId, toRoute.query.tab || DEFAULT_TAB);
        }
        if (isProfileRoute && personChanged) {
          this.forceActivateTab(toRoute.query.tab);
          return this.init(false);
        }
        if (isProfileRoute && toRoute.query.tab && toRoute.query.tab !== this.activeTab) {
          return this.forceActivateTab(toRoute.query.tab);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'familyTreePersonState',
      'familyTreePersonLoadingState',
      'familyTreePersonOptionsLoadingState',
      'familyTreePersonRelativesLoadingState',
      'familyTreePersonPrimaryFullnameEnState',
      'familyTreePersonPrimaryFullnameCnState',
      'familyTreeNameState',
      'familyTreeHintsMetaState',
      'userIsStaffState',
      'familyTreePreferencesState',
    ]),
    unknownName() {
      return this.familyTreePersonState.is_current_user ? 'You' : UNKNOWN_NAME;
    },
    livingStatus() {
      return getLifetimeYears(
        this.familyTreePersonState.birth_date,
        this.familyTreePersonState.death_date,
        this.familyTreePersonState.is_deceased
      );
    },
    personInFTRoute() {
      return {
        name: 'familytree-details',
        params: {id: this.$route.params.id},
        query: {focus_person_id: this.familyTreePersonState.object_id},
      };
    },
    profilePicture() {
      const person = this.familyTreePersonState;
      return person && person.profile_picture;
    },
    isWriteAllowed() {
      return this.familyTreePersonState.is_write_allowed;
    },
    visibleContextMenuSections() {
      let sections = [...Object.values(CONTEXT_MENU_SECTIONS)];
      const indexProfile = sections.indexOf(CONTEXT_MENU_SECTIONS.FULL_PROFILE);
      sections.splice(indexProfile, 1);
      const indexEdit = sections.indexOf(CONTEXT_MENU_SECTIONS.EDIT);
      sections.splice(indexEdit, 1);
      const indexDelete = sections.indexOf(CONTEXT_MENU_SECTIONS.DELETE);
      if (!this.familyTreePersonState.is_delete_allowed) {
        sections.splice(indexDelete, 1);
      }
      if (!this.isWriteAllowed) {
        const indexEditFull = sections.indexOf(CONTEXT_MENU_SECTIONS.FULL_PROFILE_EDIT);
        sections.splice(indexEditFull, 1);
        const indexAddRelative = sections.indexOf(CONTEXT_MENU_SECTIONS.ADD_RELATIVE);
        sections.splice(indexAddRelative, 1);
      }
      return sections;
    },
    relatives() {
      return this.$store.getters.familyRelativesByPersonIdState[this.familyTreePersonState.object_id] || {};
    },
    personHasBothParents() {
      return this.relatives && this.relatives.parents && this.relatives.parents.length === 2;
    },
    backRouteNoShift() {
      return {...this.backRoute, query: {...this.backRoute.query, noshift: true}};
    },
    pageLoading() {
      return (
        this.showLoading ||
        this.familyTreePersonLoadingState ||
        this.familyTreePersonOptionsLoadingState ||
        this.familyTreePersonRelativesLoadingState
      );
    },
    showHintsTab() {
      return this.isWriteAllowed || this.userIsStaffState;
    },
    hintsReviewCount() {
      const metaPersonId = this.familyTreeHintsMetaState.person_id
        ? this.familyTreeHintsMetaState.person_id.toString()
        : null;
      const routePersonId = this.$route.params.personId ? this.$route.params.personId.toString() : null;
      const count =
        routePersonId && metaPersonId === routePersonId ? this.familyTreeHintsMetaState.facets.status.new : 0;
      return count && count > 99 ? 99 : count;
    },
    showNoAccessNotification() {
      return !this.showHintsTab && this.activeTab === TAB_HINTS;
    },
    searchByProfileRoute() {
      return getSearchByProfileRoute(this.familyTreePersonState);
    },
    birthOrder() {
      if (this.familyTreePreferencesState.tree_show_birth_order) {
        return this.relatives && this.relatives.siblings_birth_order
          ? this.relatives.siblings_birth_order[this.familyTreePersonState.object_id]
          : null;
      }
    },
  },
  methods: {
    init(scroll) {
      const person = this.$store
        .dispatch('fetchFamilyTreePersonAction', this.$route.params.personId)
        .catch(response => {
          if (!this.$store.getters.userIsLoggedInState) {
            this.$router.push({name: 'login', query: {redirect: this.$route.fullPath}});
          }
        });
      const relatives = this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.$route.params.personId);

      if (scroll) {
        Promise.all([person, relatives]).then(() => {
          this.scrollToContent();
        });
      }
    },
    forceActivateTab(tab) {
      this.$refs.tabs ? this.$refs.tabs.activateTab(tab) : null;
    },
    onActivateTab({tabId, fromTabId}) {
      if (this.activeTab !== tabId) {
        this.activeTab = tabId;
        if (this.$route.query.tab !== tabId) {
          const newQuery = tabId === TAB_HINTS ? this.$route.query : omit(this.$route.query, ['status']);
          this.$router.push({query: {...newQuery, tab: tabId}});
        }
      }
    },
    uploadPictureClick() {
      this.$refs.pictureinput.click();
    },
    pictureSelectedHandler(event) {
      const file = event.target.files[0];
      if (file.size > this.maxFileSize) {
        return this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
      }
      const fileExtension = file.name.split('.').pop();
      if (!this.allowedFileExtensions.includes(fileExtension.toLowerCase())) {
        return this.handleFilesSelectError(`Allowed file extensions: ${this.allowedFileExtensions}`);
      }
      this.reCrop(file, URL.createObjectURL(file));
    },
    clearPictureInput() {
      this.$refs.pictureinput.value = '';
    },
    handleFilesSelectError(errorText) {
      this.clearPictureInput();
      this.$toasted.error(errorText);
    },
    openTextExtractTutorialModal(show) {
      if (show) {
        this.$modal.show(
          ExtractTextInterstitial,
          {},
          {
            clickToClose: true,
            scrollable: true,
            height: 'auto',
            classes: 'clear_modal white_modal',
            class: 'mobile_bottom',
          }
        );
      }
    },
    updateProfilePicture(profilePictureData) {
      if (!profilePictureData.blob) {
        return Promise.resolve();
      }
      return new Promise((resolve, reject) => {
        this.$store.commit('mutateFamilyTreePersonState', {
          profile_picture: {attachment: URL.createObjectURL(profilePictureData.blob)},
        });
        if (profilePictureData.file) {
          return this.$store
            .dispatch('uploadFamilyTreePersonFileAction', {
              familyTreeId: this.familyTreePersonState.family_tree_id,
              personId: this.familyTreePersonState.object_id,
              file: profilePictureData.file,
            })
            .then(res => {
              this.$store
                .dispatch('setFamilyTreePersonProfilePictureAction', {
                  personId: this.familyTreePersonState.object_id,
                  assetId: res.object_id,
                  file: profilePictureData.blob,
                })
                .then(() => {
                  this.openTextExtractTutorialModal(res.show_text_extract_tutorial);
                  resolve();
                })
                .catch(reject);
            })
            .catch(reject);
        }
        return this.$store
          .dispatch('setFamilyTreePersonProfilePictureAction', {
            personId: this.familyTreePersonState.object_id,
            assetId: profilePictureData.asset_id,
            file: profilePictureData.blob,
          })
          .then(() => {
            resolve();
          })
          .catch(reject);
      });
    },
    reCrop(file, imageUrl) {
      const props = {
        initialImageSrc: imageUrl,
        onCrop: blob => {
          this.profilePictureUpdateLoading = true;
          this.updateProfilePicture({file: file, blob: blob})
            .then(() => {
              this.$toasted.success('Profile picture saved!');
            })
            .catch(() => {
              this.$toasted.error('Error saving profile picture.');
            })
            .finally(() => {
              this.profilePictureUpdateLoading = false;
            });
          this.$modal.hide('crop');
        },
      };
      const modalParams = {classes: 'clear_modal white_modal', name: 'crop', height: 'auto', scrollable: true};
      this.$modal.show(PersonProfilePictureCrop, props, modalParams);
    },
    deletePerson() {
      const name = this.familyTreePersonPrimaryFullnameEnState || this.familyTreePersonPrimaryFullnameCnState;
      let props = {
        personName: name || 'this person',
        personId: this.familyTreePersonState.object_id,
        deleteFunction: () => {
          this.$store
            .dispatch('deleteFamilyTreePersonAction', this.familyTreePersonState.object_id)
            .then(() => {
              this.$toasted.success(`${name || 'Person'} deleted successfully!`);
              this.$router.push(this.defaultBackRoute);
            })
            .catch(() => {
              let props = {error: this.$store.getters.familyTreePersonDeleteErrorsState};
              let modalParams = {
                classes: 'clear_modal white_modal',
                class: 'mobile_bottom',
                name: `person-delete-failed`,
              };
              this.$modal.show(personDeleteFailedModalContent, props, modalParams);
            })
            .finally(() => {
              this.$modal.hide(`person-delete`);
            });
        },
      };
      let modalParams = {classes: 'clear_modal white_modal', class: 'mobile_bottom', name: `person-delete`};
      this.$modal.show(personDeleteConfirmModalContent, props, modalParams);
    },
    clickViewTree() {
      const query = {start_person_id: this.familyTreePersonState.object_id};
      this.$router.push({name: 'familytree-details', params: {id: this.familyTreePersonState.family_tree_id}, query});
    },
    clickViewLineage() {
      const query = {start_person_id: this.familyTreePersonState.object_id};
      this.$router.push({name: 'familytree-lineage', params: {id: this.familyTreePersonState.family_tree_id}, query});
    },
    searchByProfileClick() {
      if (this.searchByProfileRoute) {
        this.$router.push(this.searchByProfileRoute);
      }
    },
    addParentClick() {
      return this.openAddPersonModal(null, PARENT_RELATION_TYPE);
    },
    addSpouseClick() {
      return this.openAddPersonModal(null, SPOUSE_RELATION_TYPE);
    },
    addSiblingClick() {
      return this.openAddPersonModal(null, SIBLING_RELATION_TYPE);
    },
    addChildClick() {
      return this.openAddPersonModal(null, CHILD_RELATION_TYPE);
    },
    openAddPersonModal(initialData, relationType) {
      const modalName = 'add-relative';
      const callback = () => {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.familyTreePersonState.object_id).then(() => {
          this.forceActivateTab(TAB_RELATIONSHIPS);
          this.$toasted.success('Relative created successfully!');
          this.$modal.hide(modalName);
        });
      };
      openAddPersonModal(
        initialData,
        null,
        relationType,
        this.familyTreePersonState,
        this.familyTreePersonState.family_tree_id,
        modalName,
        false,
        false,
        callback,
        this
      );
    },
    scrollToContent() {
      this.$nextTick(() => {
        this.$scrollTo('.tabs-content', {offset: -120});
      });
    },
    activateEditMode(focus) {
      this.forceActivateTab(TAB_DETAILS);
      if (focus && focus === 'birth') {
        this.$refs.detailsTab.startEditModeWithScroll({scrollId: 'section-data-birth', focus: 'birth'});
        return;
      }
      this.$refs.detailsTab.setEditMode(true);
      this.$nextTick(() => {
        this.$scrollTo('.person-edit-form-block', {offset: -120});
      });
    },
    activateRelativesTab() {
      this.forceActivateTab(TAB_RELATIONSHIPS);
    },
    trackTabOpened(personId, tabId) {
      AnalyticsMainHandler.trackTreeOpenProfileTabEvent(personId, tabId);
    },
    handleFilesDrop(files) {
      this.forceActivateTab(this.TAB_LIBRARY);
      this.$nextTick(() => {
        this.$refs.libraryTab.handleFilesDrop(files);
      });
    },
  },
  components: {
    McrWikiGlossaryLink,
    FamilyTreeNoAccess,
    TabDetails,
    TabRelationships,
    StandOutBlock,
    TabsBar,
    TabsBlockItem,
    CameraIcon,
    PersonIconColored,
    McrButtonRouterLink,
    TreeIcon,
    ContextMenuPopover,
    BounceLoading,
    TabLibrary,
    TabHints,
    ChevronLeftIcon,
    DragDropPrompt,
    LockIcon,
  },
  name: 'FamilyTreeProfilePage',
};
</script>

<style lang="scss" scoped>
.family-tree-profile-page {
  .breadcrumb-link {
    display: flex;
    margin-bottom: 8px;
    a {
      display: flex;
      align-items: center;
      align-self: flex-start;
    }
  }

  .heading-block {
    padding: 0;
    border-radius: 0;

    .person-data-container {
      padding: 24px 24px 32px;
      display: flex;
      align-items: self-start;
      justify-content: space-between;

      .person-data {
        display: flex;
        align-items: center;

        .person-picture {
          width: 120px;
          height: 120px;
          margin-right: 24px;
          border-radius: 4px;
          overflow: hidden;
          flex-shrink: 0;
        }
        .name-container {
          display: flex;
          .birth-order {
            margin-left: 8px;
            margin-top: 6px;
            color: $neutral-600;
            @media only screen and (max-width: $breakpoint-tablet) {
              margin-top: 4px;
            }
          }
        }

        h5 {
          margin: 0;
          word-break: break-word;
        }
        .living-status {
          color: $neutral-500;
        }
        .upload-profile-picture {
          margin-top: 4px;
          display: flex;
          align-items: flex-start;
          a {
            display: flex;
            align-items: center;
            margin-right: 10px;
            .material-design-icon {
              margin-right: 4px;
            }
          }
          a.inactive {
            color: $neutral-400;
            pointer-events: none;
          }
        }

        .picture-input {
          display: none;
        }
      }
      .controls {
        display: flex;
        flex-shrink: 0;
        margin-left: 16px;

        .view-tree {
          margin-right: 8px;
          .material-design-icon {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .tabs-bar::v-deep {
    .tab.hints .material-design-icon {
      margin-left: -5px;
    }
    .tab-content .item-block {
      margin-top: 24px;
      background-color: $background-light;
      border-radius: 4px;

      &.person-details-block {
        .section-category {
          position: absolute;
          top: -16px;
          left: 16px;
          background: $background-light;
          padding: 5px;
          color: $neutral-400;
        }
      }

      .header {
        padding: 12px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $neutral-200;
        .heading-6 {
          word-break: break-word;
          color: $neutral-600;
        }

        &.no-border {
          border-bottom: none;
        }
      }
      .content {
        padding: 20px 24px;
      }
      .sections {
        display: flex;
        flex-direction: column;
        padding: 20px 0 0;
        .section {
          position: relative;
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          padding-bottom: 20px;
          padding-left: 24px;
          padding-right: 24px;

          .content-item {
            display: flex;
            position: relative;

            .label {
              font-weight: 800;
              color: $neutral-500;
              width: 240px;
              flex-shrink: 0;
            }

            .values {
              display: flex;
              flex-direction: column;
              row-gap: 8px;
              .value {
                word-break: break-word;
              }
            }
          }
        }
        .section:not(:first-of-type) {
          padding-top: 20px;
          border-top: 1px solid $neutral-200;
        }
        .section + .section.fact-section {
          margin-top: 12px;
        }

        .section.names-section {
          row-gap: 36px;
        }

        .section.fact-section .content-item {
          .values {
            flex-grow: 1;
            row-gap: 16px;
            .value {
              padding-bottom: 16px;
              border-bottom: 1px solid $neutral-200;
            }
          }

          &:last-of-type .values .value {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .no-access-notification {
    display: flex;
    align-items: center;
    margin-top: 24px;
    gap: 12px;

    .icon-container {
      border-radius: 50%;
      background: $primary-50;
      width: 56px;
      height: 56px;
      color: $primary-400;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .heading-block {
      .person-data-container {
        padding: 24px 16px;
        flex-direction: column;
        align-items: stretch;

        .person-data {
          margin-bottom: 24px;
        }

        .controls {
          margin-left: 0;
          .view-tree {
            flex-grow: 1;
          }
        }
      }
    }

    .tabs-bar::v-deep {
      .tab-content .item-block {
        .content {
          padding: 16px;
        }
        .sections {
          padding: 16px 0 0;
          .section {
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 16px;
          }
          .section:not(:first-of-type) {
            padding-top: 16px;
          }
          .section.fact-section .content-item {
            .values {
              row-gap: 12px;
              .value {
                padding-bottom: 12px;
              }
            }

            &:last-of-type .values .value {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>
