<template>
  <div class="village-details-page">
    <div class="readable_content">
      <div class="title-wrapper">
        <h1 class="title">{{ title }}</h1>
        <share-url-button :route="shareRoute" :title="shareTitle" :track-params="shareTrackParams"></share-url-button>
      </div>
      <div class="sub-section wiki-article" v-if="placeState.wiki_article" v-prerender-if="'wiki_article.content'">
        <div v-html="placeState.wiki_article.content"></div>
        <div class="read-more-link" v-if="!isTemplate">
          <router-link :to="wikiArticleRoute" class="bold">Read more on the Genealogy Wiki</router-link>
        </div>
      </div>
      <div
        v-html="placeState.description"
        v-if="isTemplate || (placeState.description && !placeState.wiki_article)"
        v-prerender-if="'description'"
      ></div>
      <div
        v-html="placeState.migration_meta.description"
        v-if="isTemplate || (placeState.migration_meta.description && !placeState.wiki_article)"
        v-prerender-if="'migration_meta.description'"
      ></div>
      <section-related-clans
        v-if="placeState.clans && placeState.clans.length"
        v-prerender-if="'clans'"
        :clans="placeState.clans"
        :is-template="isTemplate"
        class="sub-section"
      ></section-related-clans>
      <section-also-known-as
        v-if="placeState.aliases && placeState.aliases.length"
        v-prerender-if="'aliases'"
        :aliases="placeState.aliases"
        :is-template="isTemplate"
        class="sub-section"
      ></section-also-known-as>
      <div
        class="sub-section"
        v-if="placeState.migration_meta.est_population"
        v-prerender-if="'migration_meta.est_population'"
      >
        <span><strong>Estimated Population: </strong></span>
        <span>{{ placeState.migration_meta.est_population.toLocaleString('en') }}</span>
      </div>
      <section-dialects
        v-if="placeState.dialects_list && placeState.dialects_list.length"
        v-prerender-if="'dialects_list'"
        :dialects="placeState.dialects_list"
        :is-template="isTemplate"
        class="sub-section"
      >
      </section-dialects>
      <section-migration-destinations
        v-if="placeState.migration_places && placeState.migration_places.length"
        v-prerender-if="'migration_places'"
        :places="placeState.migration_places"
        :is-template="isTemplate"
        class="sub-section"
      ></section-migration-destinations>
      <div v-if="placeState.is_qiao_xiang" v-prerender-if="'is_qiao_xiang'" class="ancestral-home sub-section">
        {{ placeState.pinyin }} is the ancestral home of many Overseas Chinese.
      </div>
      <section-wiki-articles
        class="sub-section"
        :place-id="placeState.id"
        :exclude-slug="placeState.wiki_article && placeState.wiki_article.slug"
        v-if="!isTemplate"
      ></section-wiki-articles>
      <cta-block-start-searching
        v-if="!userIsLoggedInState"
        :pinyin="placeState.pinyin"
        class="section"
      ></cta-block-start-searching>
      <save-surname-block
        v-if="userIsLoggedInState"
        class="section"
        :surname="placeState.pinyin"
        :surnames-to-save="[]"
        :has-surname="true"
      ></save-surname-block>

      <section-google-map
        v-if="showMap"
        class="section"
        :address="googleMapAddress"
        :name="placeState.name"
        :level="placeState.level"
        :coordinates="placeState.location"
        @set-marker="onMarkerSet"
      ></section-google-map>
      <div class="section map-placeholder" v-else>
        <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
      </div>

      <section-address
        v-if="placeState.level !== 0"
        :items="placeState.ancestors"
        :full-address-en="placeState.full_address_en"
        :full-address-ch="placeState.full_address_ch"
        :is-template="isTemplate"
      ></section-address>

      <section-zupus
        v-if="sectionRecordZupusCount"
        :pinyin="placeState.address_en"
        :count="sectionRecordZupusCount"
        class="section"
      ></section-zupus>

      <section-records
        v-if="showSectionRecords"
        :pinyin="placeState.address_en"
        :place-id="placeState.id"
        :meta="searchRecordsMeta"
        class="section"
      ></section-records>

      <ancestral-locations-section
        v-if="placeState.migration_meta.place_id"
        class="section"
        :pinyin="placeState.pinyin"
        :place-id="placeState.id"
        :description="placeState.migration_meta.origin_description"
        :description-prerender-name="'migration_meta.origin_description'"
        :is-template="isTemplate"
      >
        <h5 slot="title">Where did the Chinese of {{ placeState.pinyin }} migrate from?</h5>
      </ancestral-locations-section>

      <section-descendants
        v-if="placeDescendantsSearchState.length && placeDescendantsSearchMetaState.total_count"
        v-prerender-if="'descendants'"
        :place="placeState"
        :descendants="placeDescendantsSearchState"
        :meta="placeDescendantsSearchMetaState"
        class="section"
        @onSwitchPage="searchDescendants"
      ></section-descendants>

      <popular-surnames-section class="section" :pinyin="placeState.pinyin"></popular-surnames-section>
      <cta-block-compare-plans class="section"></cta-block-compare-plans>
    </div>
  </div>
</template>

<script>
import ShareUrlButton from '@common/elements/buttons/ShareUrlButton';
import AncestralLocationsSection from '@common/pages/placeDetails/AncestralLocationsSection';
import PopularSurnamesSection from '@common/pages/placeDetails/PopularSurnamesSection';
import SectionGoogleMap from '@common/pages/placeDetails/SectionGoogleMap';
import SectionRecords from '@common/pages/placeDetails/SectionRecords.vue';
import SectionWikiArticles from '@common/pages/placeDetails/SectionWikiArticles';
import SectionAddress from '@common/pages/placeDetails/village/SectionAddress';
import SectionAlsoKnownAs from '@common/pages/placeDetails/village/SectionAlsoKnownAs';
import SectionDescendants from '@common/pages/placeDetails/village/SectionDescendants';
import SectionDialects from '@common/pages/placeDetails/village/SectionDialects';
import SectionMigrationDestinations from '@common/pages/placeDetails/village/SectionMigrationDestinations';
import SectionRelatedClans from '@common/pages/placeDetails/village/SectionRelatedClans';
import SectionZupus from '@common/pages/placeDetails/village/SectionZupus';
import {sourceTypeCategories} from '@common/utils/consts.source';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans';
import CtaBlockStartSearching from '@/components/common/cta/ctaBlockStartSearching';

import SaveSurnameBlock from '@/components/modules/surnameDetails/common/SaveSurnameBlock';

import descendantsData from '../prerender/descendantsData';
import placeData from '../prerender/placeData';
import placeSearchMeta from '../prerender/placeSearchMeta';
import spellingsListData from '../prerender/spellingsListData';

export default {
  metaInfo() {
    const {name, address_en, pinyin, aliases, full_address_en, full_address_ch} = this.placeState;
    const mcrLabel = 'My China Roots Village Database';
    const addressEn = full_address_en ? full_address_en.replace(`${address_en}, `, '') : '';
    const addressCn = full_address_ch ? full_address_ch.replace(`${name}`, '') : '';
    const aliasesSrt = aliases ? aliases.join(', ') : '';
    const placeName = pinyin === name ? pinyin : `${pinyin} ${name}`;
    return {
      title: name ? `${placeName} - ${mcrLabel}` : mcrLabel,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `${pinyin} is located in ${addressEn} ${addressCn}. Also known as ${aliasesSrt}.`,
        },
      ],
    };
  },
  created() {
    if (this.$route.meta.isPrerenderTemplate) {
      this.isTemplate = true;
      this.$store.commit('setPlaceState', placeData);
      this.$store.commit('setPlaceDescendantsSearchMetaState', descendantsData.meta);
      this.$store.commit('setPlaceDescendantsSearchState', descendantsData.objects);
      this.$store.commit('setSpellingSearchState', spellingsListData);
      this.$store.commit('setPlaceSearchMetaState', placeSearchMeta);
      return;
    }
    if (window.__PRERENDERED_STATE__) {
      this.$store.commit('setPlaceState', window.__PRERENDERED_STATE__.place);
      this.$store.commit('setPlaceDescendantsSearchMetaState', window.__PRERENDERED_STATE__.descendants.meta);
      this.$store.commit('setPlaceDescendantsSearchState', window.__PRERENDERED_STATE__.descendants.objects);
      this.$store.commit('setSpellingSearchState', window.__PRERENDERED_STATE__.spellings);
      this.$store.commit('setPlaceSearchMetaState', window.__PRERENDERED_STATE__.place_search_meta);
      window.__PRERENDERED_STATE__ = null;
      return;
    }
    // set showMap true in 1 second
    setTimeout(() => {
      this.showMap = true;
    }, 1000);
    this.init();
  },
  data() {
    return {
      isTemplate: false,
      showMap: false,
      searchRecordsMeta: {},
    };
  },
  computed: {
    ...mapGetters([
      'placeState',
      'userIsLoggedInState',
      'placeDescendantsSearchMetaState',
      'placeDescendantsSearchState',
    ]),
    title() {
      const addressEn = this.placeState.display_text_short || '';
      const name = this.placeState.name || '';
      if (addressEn === name || addressEn.includes(name)) {
        return addressEn;
      }
      return `${addressEn} ${name}`;
    },
    shareRoute() {
      return getPlaceDetailRoute(this.placeState.id, this.placeState.address_en);
    },
    shareTitle() {
      return `Check out this page I found on ${this.placeState.address_en} in China: `;
    },
    shareTrackParams() {
      return {place: this.placeState.address_en};
    },
    googleMapAddress() {
      // hack: google can't find Taiwan by chinese name
      return this.placeState.full_address_ch === '台湾'
        ? this.placeState.full_address_en
        : this.placeState.full_address_ch;
    },
    showSectionRecords() {
      return !this.isTemplate && this.searchRecordsMeta.facets && !isEmpty(this.searchRecordsMeta.facets.category_id);
    },
    sectionRecordZupusCount() {
      if (this.isTemplate) {
        return 0;
      }
      const sourcesMeta = this.searchRecordsMeta.sourcesMeta;
      const meta =
        sourcesMeta && sourcesMeta.facets && sourcesMeta.facets.category_id
          ? sourcesMeta.facets.category_id[sourceTypeCategories.CATEGORY_ZUPUS]
          : null;
      return meta && meta.count ? meta.count : 0;
    },
    wikiArticleRoute() {
      if (this.placeState.wiki_article) {
        return {name: 'wiki-article', params: {slug: this.placeState.wiki_article.slug}};
      }
    },
  },
  methods: {
    init() {
      this.$store.commit('setPlaceDescendantsSearchMetaState', {});
      this.$store.commit('setPlaceDescendantsSearchState', null);
      this.searchRecordsMeta = {};

      if (this.placeState.next_level) {
        this.searchDescendants(1);
      }

      const searchMentionsPromise = this.$store.dispatch('searchAllRecordsSilentAction', {
        auto_place_id: this.$route.params.placeId,
        only_meta: true,
      });
      const searchSourcesPromise = this.$store.dispatch('searchSourcesInBackgroundAction', {
        auto_place_id: this.$route.params.placeId,
        only_meta: true,
      });
      Promise.all([searchMentionsPromise, searchSourcesPromise]).then(responses => {
        const meta = {...responses[0].meta, sourcesMeta: responses[1].meta};
        this.searchRecordsMeta = {...meta};
      });
      if (!this.$store.getters.searchCategoriesListState.length) {
        this.$store.dispatch('fetchSearchCategoriesListAction');
      }

      if (this.placeState.migration_meta.place_id) {
        this.$store.dispatch('searchAncestralPlacesAction', {migration_place_id: this.$route.params.placeId});
        this.$store.dispatch('searchSpellingsAction', {place_id: this.$route.params.placeId, limit: 28});
      }
    },
    searchDescendants(page) {
      this.$store.dispatch('searchPlaceDescendantsAction', {
        root: this.placeState.id,
        level: this.placeState.next_level,
        fields: 'id,address_en,name,pinyin',
        is_ancestral_place: '1',
        page,
      });
    },
    onMarkerSet({location}) {
      if (isEmpty(this.placeState.location)) {
        this.$store.commit('setPlaceLocationState', location);
        this.$store.dispatch('savePlaceLocationAction', {id: this.placeState.id, location});
      }
    },
  },
  components: {
    SectionRecords,
    CtaBlockComparePlans,
    SectionWikiArticles,
    PopularSurnamesSection,
    AncestralLocationsSection,
    ShareUrlButton,
    SaveSurnameBlock,
    SectionDescendants,
    CtaBlockStartSearching,
    SectionAddress,
    SectionGoogleMap,
    SectionMigrationDestinations,
    SectionDialects,
    SectionAlsoKnownAs,
    SectionRelatedClans,
    SectionZupus,
  },
};
</script>

<style lang="scss" scoped>
.village-details-page {
  min-height: 100vh;
}

.title-wrapper {
  display: flex;
  border-bottom: 1px solid $divider-line-color;
  padding-bottom: 20px;
  margin-bottom: 16px;
  align-items: flex-end;
  justify-content: space-between;
  .title {
    word-break: break-word;
    margin-right: 15px;
    margin-bottom: 0;
  }
}

.sub-section {
  margin-top: 18px;
}

.section {
  margin-top: 60px;
}

.ancestral-home {
  color: $mcr-dark-tan;
}

.map-placeholder {
  height: 500px;
  display: flex;
  background-color: #e5e0dc;
}
.wiki-article {
  padding-bottom: 16px;
  border-bottom: 1px solid $divider-line-color;
}
.read-more-link {
  margin-top: 8px;
}
</style>
