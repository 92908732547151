<template>
  <div class="contact-page">
    <div class="readable_content">
      <h1>Contact Us</h1>
      <div class="contact-content">
        <div class="contact-emails">
          <p>
            <strong>General Inquiries</strong><br />
            <a href="mailto:inquiries@mychinaroots.com">inquiries@mychinaroots.com</a><br />
            Office: +86 18928760761
          </p>
          <p>
            <strong>Research</strong><br />
            Liu Jingwen, Research Manager<br />
            <a href="mailto:liujingwen@mychinaroots.com">liujingwen@mychinaroots.com</a>
          </p>
          <p>
            <strong>Partnerships &amp; Speaking<br /></strong> Huihan Lie, Founder-CEO<br />
            <a href="mailto:huihanlie@mychinaroots.com">huihanlie@mychinaroots.com</a>
          </p>
          <p>
            <strong>Media &amp; Press Inquiries</strong><br />
            Huihan Lie, Founder-CEO<br />
            <a href="mailto:huihanlie@mychinaroots.com">huihanlie@mychinaroots.com</a>
          </p>
          <p>
            <strong>Employment Opportunities</strong><br />
            <a href="mailto:recruitment@mychinaroots.com">recruitment@mychinaroots.com</a>
          </p>
          <p>
            <strong>Tech Support</strong><br />
            <a href="mailto:support@mychinaroots.com">support@mychinaroots.com</a>
          </p>
        </div>
        <div class="contact-addresses">
          <hr />
          <p><strong>Headquarters</strong></p>
          <div>My China Roots</div>
          <div>20 Collyer Quay</div>
          <div>#09-01</div>
          <div>Singapore, 049319</div>
          <business-address-map height="400"></business-address-map>
          <p>
            <strong>Branches</strong><br />
            Guangzhou, San Francisco, London
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessAddressMap from '@/components/common/maps/BusinessAddressMap';

export default {
  components: {BusinessAddressMap},
  metaInfo: {
    title: 'Contact Us',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Get in touch to start tracing your roots or find out more about our services and partnerships!',
      },
    ],
  },
  name: 'contact.page.vue',
};
</script>

<style lang="scss" scoped>
.contact-content {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  > div {
  }
  .contact-addresses {
    margin-left: 28px;
    max-width: 50%;
    hr {
      display: none;
    }
    p:first-of-type {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    .contact-addresses {
      max-width: none;
      margin-left: 0;
      hr {
        display: block;
        margin-bottom: $paragraph-margin-size;
      }
    }
  }

  .embeded-google-map {
    margin-top: 20px;
  }
}
</style>
