<template>
  <div class="image-to-text-results-page" v-if="pageNotFound"><slot name="page-not-found"></slot></div>
  <div class="image-to-text-results-page" v-else-if="newImageLoading">
    <mcr-loading-indicator :loading="true" message="Uploading... Do not close this page."></mcr-loading-indicator>
  </div>
  <div class="image-to-text-results-page" v-else>
    <image-to-text-input ref="new-image-input" @upload-started="uploadNewImageStarted"></image-to-text-input>
    <div class="heading" v-if="isDesktop">
      <div>
        <div class="text-lg">Image to Text</div>
        <div class="text-sm">{{ imageName }}</div>
      </div>
      <template v-if="!imageToTextLoadingState">
        <v-dropdown
          :auto-hide="true"
          :offset="10"
          placement="bottom-end"
          :popper-triggers="['click']"
          v-if="allowLibrary"
        >
          <mcr-button class="control-button white small">
            <span>New image</span>
            <chevron-down :size="20"></chevron-down>
          </mcr-button>
          <new-image-sub-actions
            slot="popper"
            class="popover-content"
            @upload="uploadNewImageClick"
            @select-from-library="selectFromLibraryClick"
          >
          </new-image-sub-actions>
        </v-dropdown>
        <mcr-button class="white small" @click="uploadNewImageClick" v-else>New image</mcr-button>
      </template>
    </div>
    <div class="heading mobile" v-else>
      <div class="text-md">Select the area to extract text from</div>
      <div class="upload-icon-container" @click="uploadNewImageClickMobile"><upload-icon :size="20"></upload-icon></div>
    </div>
    <div class="body">
      <div class="image-side" v-if="imageToTextLoadingState">
        <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
      </div>
      <div class="image-side" v-else-if="isDesktop">
        <div class="image-container">
          <area-selection-overlay
            v-if="imageWidth && imageHeight"
            ref="area-selection-overlay"
            :image-width="imageWidth"
            :image-height="imageHeight"
            :scale="1"
            :render-scale="imageRenderScale"
            :url="imageSrc"
            :disabled="ocrLoading"
            @area-selected="onAreaSelected"
          >
          </area-selection-overlay>
          <img v-if="imageSrc" :src="imageSrc" ref="image" @load="onImageLoad" />
        </div>
        <div class="help-prompt">
          <crop-icon :size="30" /> <span class="text-md">Drag and drop to select text to extract</span>
        </div>
      </div>
      <div class="image-side" v-else>
        <area-selection-mobile
          :src="imageSrc"
          ref="area-selection-mobile"
          @change="onMobileAreaChange"
        ></area-selection-mobile>
      </div>
      <div class="ocr-side" v-if="isDesktop">
        <div class="ocr-side-heading text-lg">Selected Area OCR</div>
        <div class="ocr-side-content">
          <div class="language-block" v-if="!imageToTextLoadingState">
            <ocr-type-select :value="ocrType" :options="langOptionsAsArray" @select="onOcrTypeSelect"></ocr-type-select>
            <div class="translation-block" v-if="userCanTranslate">
              <div class="text-md">Show translation</div>
              <switch-toggle
                class="small"
                :value="showTranslation"
                @toggle="toggleShowTranslation"
                v-tooltip="switchToggleTooltip"
              ></switch-toggle>
            </div>
          </div>

          <div class="result-block" ref="result-block">
            <div class="translated-text loading supplemental" v-if="translatedTextLoading">Translating...</div>
            <div class="translated-text text-xl" v-if="translatedText && showTranslation">{{ translatedText }}</div>
            <div class="ocr-text text-xl">{{ ocrText }}</div>
            <div class="error">{{ errorText }}</div>
            <div class="loading supplemental" v-if="ocrLoading">Processing image snippet...</div>
          </div>
        </div>
        <div class="promo" v-if="!userCanTranslate">
          <translate-subscription-banner @click.native="goToSubscriptionPlans"></translate-subscription-banner>
        </div>
      </div>
    </div>

    <div class="action-buttons" v-if="!isDesktop">
      <mcr-button @click="extractAreaMobile">Extract Selected Area</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import SwitchToggle from '@common/elements/inputs/SwitchToggle';
import AreaSelectionMobile from '@common/elements/layouts/area-selection/AreaSelectionMobile.vue';
import AreaSelectionOverlay from '@common/elements/layouts/area-selection/AreaSelectionOverlay.vue';
import OcrTypeSelect from '@common/elements/layouts/area-selection/OcrTypeSelect';
import TranslateSubscriptionBanner from '@common/elements/layouts/area-selection/TranslateSubscriptionBanner';
import NewImageSubActionsModal from '@common/pages/imageToText/NewImageSubActionsModal.vue';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {isChineseText} from '@common/utils/utils';
import {gtLangOptionsAsArray, openOcrAreaResultsModal, SUBSCRIBE_OPTION} from '@common/utils/utils.ocr';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';
import ChevronDown from 'vue-material-design-icons/ChevronDown';
import CropIcon from 'vue-material-design-icons/Crop';
import UploadIcon from 'vue-material-design-icons/Upload';
import {mapGetters} from 'vuex';

import ImageToTextInput from './ImageToTextInput';
import NewImageSubActions from './NewImageSubActions';

export default {
  props: {
    allowLibrary: {type: Boolean, default: false},
  },
  data() {
    return {
      pageNotFound: false,
      newImageLoading: false,
      imageWidth: 0,
      imageHeight: 0,
      naturalWidth: 0,
      naturalHeight: 0,
      areaSelected: {},
      ocrType: null,
      ocrLoading: false,
      ocrText: '',
      translatedText: '',
      translatedTextLoading: false,
      showTranslation: false,
      errorText: '',
    };
  },
  created() {
    if (!this.userIsSubscribedState && !this.subscriptionPlansState.length) {
      this.$store.dispatch('fetchSubscriptionPlansAction');
      this.$store.dispatch('fetchSubscriptionsAction');
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    windowWidthState(value) {
      this.adjustVisibleArea();
    },
    '$route.params': {
      handler: function (toParams, fromParams) {
        if (toParams.assetId !== fromParams.assetId) {
          this.init();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      'userIsStaffState',
      'userIsSubscribedState',
      'isTrialEligibleState',
      'standardYearlyPlanState',
      'imageToTextState',
      'imageToTextLoadingState',
      'subscriptionPlansState',
      'windowWidthState',
    ]),
    langOptionsAsArray() {
      const planName = this.standardYearlyPlanState ? this.standardYearlyPlanState.display_text : 'Detective';
      return gtLangOptionsAsArray(
        this.userIsStaffState,
        this.userIsSubscribedState,
        this.isTrialEligibleState,
        planName
      );
    },
    imageName() {
      return this.imageToTextState && this.imageToTextState.filename ? this.imageToTextState.filename : 'Loading...';
    },
    imageSrc() {
      return this.imageToTextState && this.imageToTextState.attachment;
    },
    userCanTranslate() {
      return this.userIsStaffState || this.userIsSubscribedState;
    },
    imageRenderScale() {
      if (!this.$refs.image) {
        return 1;
      }
      const widthScale = this.naturalWidth / this.imageWidth;
      const heightScale = this.naturalHeight / this.imageHeight;
      return Math.max(widthScale, heightScale);
    },
    maxImageSize() {
      return 300;
    },
    scale() {
      if (this.snippetWidth > this.maxImageSize) {
        return this.maxImageSize / this.snippetWidth;
      }
      return 1;
    },
    left() {
      return Math.min(this.areaSelected.x, this.areaSelected.x2);
    },
    top() {
      return Math.min(this.areaSelected.y, this.areaSelected.y2);
    },
    snippetWidth() {
      return Math.abs(this.areaSelected.x - this.areaSelected.x2);
    },
    snippetHeight() {
      return Math.abs(this.areaSelected.y - this.areaSelected.y2);
    },
    switchToggleEnabled() {
      return this.ocrText && isChineseText(this.ocrText);
    },
    switchToggleTooltip() {
      if (!this.switchToggleEnabled) {
        return {
          content: 'Translation is available for Chinese extracted text only',
          container: false,
          triggers: ['click', 'hover'],
        };
      }
    },
    isDesktop() {
      return this.windowWidthState > this.$breakpoints.mobile;
    },
  },
  methods: {
    init() {
      this.areaSelected = {};
      this.ocrText = '';
      this.translatedText = '';
      this.showTranslation = false;
      this.errorText = '';
      this.$store.commit('setImageToTextState', null);
      if (this.newImageLoading) {
        this.newImageLoading = false;
        if (this.allowLibrary) {
          const action = {text: 'View', push: {name: 'familytree-library', params: {id: this.userFamilyTreeIdState}}};
          this.$toasted.success('Image is added to Photos & Files.', {action});
        }
      }
      this.setInitialOcrType();
      this.$store.dispatch('getImageForImageToTextAction', {assetId: this.$route.params.assetId}).catch(() => {
        this.pageNotFound = true;
      });
    },
    setInitialOcrType() {
      const premiumAccess = this.userIsSubscribedState || this.userIsStaffState;
      const lang = premiumAccess ? 'baidu_ocr' : 'cn';
      this.ocrType = this.langOptionsAsArray.find(item => item.id === lang) || this.langOptionsAsArray[0];
    },
    goToSubscriptionPlans() {
      const route = getSubscriptionWallRoute(this.$route.fullPath, null, false);
      this.$router.push(route);
    },
    adjustVisibleArea() {
      this.imageWidth = this.$refs.image ? this.$refs.image.width : 0;
      this.imageHeight = this.$refs.image ? this.$refs.image.height : 0;
    },
    onImageLoad() {
      this.adjustVisibleArea();
      this.naturalWidth = this.$refs.image ? this.$refs.image.naturalWidth : 0;
      this.naturalHeight = this.$refs.image ? this.$refs.image.naturalHeight : 0;
      this.$nextTick(() => {
        if (this.$refs['area-selection-overlay']) {
          this.$refs['area-selection-overlay'].selectAll(this.isDesktop);
          this.areaSelected = this.$refs['area-selection-overlay'].areaSelected;
        }
      });
    },
    extractAreaMobile() {
      return openOcrAreaResultsModal(
        this,
        this.imageWidth,
        this.imageHeight,
        0,
        this.imageSrc,
        this.areaSelected,
        this.imageRenderScale,
        'New Selection'
      );
    },
    onMobileAreaChange(data) {
      this.areaSelected = {x: data.x1, y: data.y1, x2: data.x2, y2: data.y2};
      this.imageWidth = data.imageWidth;
      this.imageHeight = data.imageHeight;
    },
    onAreaSelected(areaSelected) {
      this.areaSelected = areaSelected;
      if (this.isDesktop) {
        this.runOcrSelection();
      }
    },
    runOcrSelection() {
      if (!this.areaSelected.x && !this.areaSelected.x2) {
        return;
      }
      this.ocrLoading = true;
      this.ocrText = '';
      this.translatedText = '';
      this.showTranslation = false;
      this.errorText = '';
      const params = {
        url: this.imageSrc,
        lang: this.ocrType.id,
        x1: Math.floor(Math.min(this.areaSelected.x, this.areaSelected.x2) * this.imageRenderScale) - 4,
        x2: Math.ceil(Math.max(this.areaSelected.x, this.areaSelected.x2) * this.imageRenderScale) + 4,
        y1: Math.floor(Math.min(this.areaSelected.y, this.areaSelected.y2) * this.imageRenderScale) - 4,
        y2: Math.ceil(Math.max(this.areaSelected.y, this.areaSelected.y2) * this.imageRenderScale) + 4,
      };
      this.$store
        .dispatch('runOcrForImageArea', params)
        .then(params => {
          this.ocrLoading = false;
          const fullText = params.full_text ? params.full_text.trim() : '';
          this.ocrText = fullText || 'Text not detected.';
          AnalyticsMainHandler.trackSelectAreaOcrResultsEvent(
            this.ocrType.id,
            Boolean(fullText),
            getRoutePageName(this.$route)
          );
        })
        .catch(err => {
          this.ocrLoading = false;
          const errorText =
            err.response && err.response.data && err.response.data.detail ? err.response.data.detail[0].msg : err;
          this.errorText = 'Error during OCR: ' + errorText;
        });
    },
    onOcrTypeSelect(value) {
      if (value.id.startsWith(SUBSCRIBE_OPTION)) {
        this.$router.push(getSubscriptionWallRoute(this.$route.fullPath, null, false));
        return;
      }
      this.ocrType = value;
      this.runOcrSelection();
    },
    toggleShowTranslation() {
      if (this.switchToggleEnabled) {
        this.showTranslation = !this.showTranslation;
        if (this.showTranslation && !this.translatedText) {
          this.translatedTextLoading = true;
          this.$store
            .dispatch('translateTextAction', {text: this.ocrText})
            .then(response => {
              this.translatedText = response.translated_text;
            })
            .finally(() => {
              this.translatedTextLoading = false;
            });
        }
      }
    },
    uploadNewImageClick() {
      this.$refs['new-image-input'].$refs.input.click();
    },
    selectFromLibraryClick() {
      this.$emit('select-from-library');
    },
    uploadNewImageClickMobile() {
      if (this.allowLibrary) {
        this.$modal.show(
          NewImageSubActionsModal,
          {
            selectFromLibrary: this.selectFromLibraryClick,
            uploadFromDevice: this.uploadNewImageClick,
          },
          {
            clickToClose: true,
            class: 'mobile_bottom',
            classes: 'clear_modal white_modal',
            transition: 'info-bar-slide-bottom',
          }
        );
      } else {
        this.uploadNewImageClick();
      }
    },
    uploadNewImageStarted() {
      this.newImageLoading = true;
    },
  },
  components: {
    AreaSelectionMobile,
    OcrTypeSelect,
    AreaSelectionOverlay,
    SwitchToggle,
    TranslateSubscriptionBanner,
    NewImageSubActions,
    ImageToTextInput,
    McrButton,
    ChevronDown,
    CropIcon,
    UploadIcon,
  },
  name: 'ImageToTextResultsBasePage',
};
</script>

<style scoped lang="scss">
.image-to-text-results-page {
  height: calc(100vh - #{$main-menu-height});
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: $neutral-100;

  .heading {
    padding: 12px 20px;
    background: $background-light;
    border-bottom: 1px solid $neutral-200;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-lg {
      font-weight: 500;
      color: $neutral-600;
    }
    .text-sm {
      color: $neutral-500;
    }
    .control-button::v-deep .mcr-button-label {
      gap: 8px;
    }
  }
  .heading.mobile {
    padding: 16px;
    .text-md {
      color: $neutral-600;
      font-weight: 800;
    }
    .upload-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $neutral-400;
    }
  }
  .body {
    display: flex;
    height: calc(100vh - #{$main-menu-height} - 73px);

    .ocr-side,
    .image-side {
      display: flex;
      flex: 1 1 0;
      overflow: hidden;
      box-sizing: border-box;
    }
    .image-side {
      background: $neutral-100;
      padding: 47px 20px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 1;
        height: calc(100% - 74px);
        box-sizing: border-box;
      }

      .help-prompt {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 16px;
        margin-top: 12px;
        color: $neutral-600;

        .material-design-icon {
          color: $primary-200;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;

        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer */
        -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
        -webkit-user-select: none; /* Chrome, Safari, and Opera */
        -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
      }
    }
    .ocr-side {
      max-width: 640px;
      background: $background-light;
      flex-direction: column;
      .ocr-side-heading {
        padding: 16px 24px;
        color: $neutral-600;
        font-weight: 800;
        border-bottom: 1px solid $neutral-200;
      }
      .ocr-side-content {
        overflow-y: scroll;
        padding: 0 16px;
        flex-grow: 1;
      }

      .language-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0;
        column-gap: 16px;
        > * {
          width: 100%;
        }
        .translation-block {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: $neutral-800;
          column-gap: 16px;
        }
      }

      .result-block {
        padding-bottom: 20px;
        .translated-text {
          padding: 20px;
          font-style: italic;
          background: $neutral-100;
          margin-bottom: 24px;
        }

        .ocr-text,
        .translated-text {
          white-space: pre-wrap;
          word-break: break-word;
          font-weight: 400;
        }
      }

      .promo {
        border-top: 1px solid $neutral-200;
      }
    }
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    height: calc(100vh - #{$main-menu-height-mobile});
    overflow: hidden;
    .body {
      height: calc(100vh - #{$main-menu-height-mobile} - 73px);
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .body {
      position: relative;
      max-height: 470px;

      .image-side {
        padding: 24px 16px;
        justify-content: flex-start;
        .image-container {
          height: 100%;
          align-items: flex-start;
        }
      }
    }
    .action-buttons {
      background: $background-light;
      z-index: 100;
      border-top: 1px solid $neutral-200;
      display: flex;
      justify-content: stretch;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px 20px;
      .mcr-button {
        width: 100%;
      }
    }
  }
}
</style>
