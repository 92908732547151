<template>
  <div class="main_menu">
    <router-link :to="{name: 'main-page'}" class="mobile-logo" v-if="!userIsLoggedInState">
      <img :src="$getAsset('/assets/mcr_logo_dude_transparent.png')" />
    </router-link>
    <router-link :to="{name: 'main-page'}" class="logo">
      <img :src="$getAsset('/assets/mcr_logo_white_transparent.png')" />
    </router-link>

    <main-menu-item
      v-for="(item, index) of displayMenuItems"
      :key="index"
      :text="item.text"
      :mobile-text="item.mobileText"
      :mobile-se-text="item.mobileSeText"
      :sub-menu="item.subMenu"
      :is-user-item="item.isUserItem"
      :is-login-item="item.isLoginItem"
      :is-button-router-link-item="item.isButtonRouterLinkItem"
      :route="item.route"
      :icon="item.icon"
      :icon-alt-text="item.iconAltText"
      :tooltip="item.tooltip"
      :need-review="item.needReview"
      :separated="item.separated"
      :is-mobile-view="isMobileView"
      :class="item.classes"
      :click-handler="item.clickHandler"
    ></main-menu-item>
  </div>
</template>

<script>
import MainMenuItem from '@common/elements/main-menu/main.menu.item';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {sourceTypeCategories} from '@common/utils/consts.source';
import {stopImpersonation} from '@common/utils/impersonation';
import {getFamilyTreeRoute, getFamilyTreeLibraryRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'userEmailState',
      'userIsLoggedInState',
      'userIsSharedState',
      'userIsSubscribedState',
      'userFirstNameState',
      'userFamilyTreeIdState',
      'userHintsTreeIdState',
      'impersonatedUserEmailState',
      'userFwsUsernameState',
      'windowWidthState',
      'featureSwitchesState',
      'featureSwitchesLoadedState',
      'reviewedItemsState',
      'activeFamilyTreeIdState',
    ]),
    displayMenuItems() {
      return this.menuItems.filter(item => {
        return this.windowWidthState >= (item.minScreen || 0) && this.windowWidthState <= (item.maxScreen || Infinity);
      });
    },
    menuItems() {
      let searchRecordsSubMenu = [
        this.getRouterLinkItem('All Records', 'search-all-records', null, {tab: TAB_ID_INDEXED_RECORDS}),
        this.getRouterLinkItem('Zupus (Family Tree Books)', 'search-all-records', null, {
          tab: TAB_ID_FULL_TEXT,
          category_id: sourceTypeCategories.CATEGORY_ZUPUS,
        }),
        this.getRouterLinkItem('Birth, Marriage & Death', 'search-all-records', null, {
          tab: TAB_ID_INDEXED_RECORDS,
          category_id: sourceTypeCategories.CATEGORY_BIRTH_DEATH,
        }),
        this.getRouterLinkItem('Immigration & Travel', 'search-all-records', null, {
          tab: TAB_ID_INDEXED_RECORDS,
          category_id: sourceTypeCategories.CATEGORY_IMMIGRATION,
        }),
        this.getRouterLinkItem('Census Records', 'search-all-records', null, {
          tab: TAB_ID_INDEXED_RECORDS,
          category_id: sourceTypeCategories.CATEGORY_CENSUS,
        }),
        this.getRouterLinkItem('Chinese Surnames', 'search-tool-surname'),
        this.getRouterLinkItem('Chinese Villages', 'search-tool-village'),
        this.getRouterLinkItem('Overseas Chinese Communities', 'search-overseas'),
      ];
      let resourcesSubMenu = [
        this.getRouterLinkItem('Genealogy Wiki', 'wiki-index'),
        this.getExternalLinkItem('Blog', '/blog'),
        this.getRouterLinkItem('Discord Community', 'community'),
        this.getRouterLinkItem('Custom Research', 'services-main'),
        this.getExternalLinkItem('For Institutions', process.env.VUE_APP_LIBRARY_SITE_BASE),
      ];
      resourcesSubMenu.splice(2, 0, this.getRouterLinkItem('Workshops & Events', 'events'));
      const familyTreeRoute = getFamilyTreeRoute(this.$store);
      const libraryRoute = getFamilyTreeLibraryRoute(this.$store);
      const viewTreeLabel = this.userIsLoggedInState ? 'View Tree' : 'Build Your Tree';
      let familySubMenu = [this.getRouterLinkItem(viewTreeLabel, familyTreeRoute.name, familyTreeRoute.params)];
      if (this.userIsLoggedInState) {
        familySubMenu.push(
          this.getRouterLinkItem('RecordFinder™', 'familytree-hints', {id: this.activeFamilyTreeIdState}, {tab: 'new'})
        );
      } else {
        familySubMenu.push(this.getRouterLinkItem('RecordFinder™', 'familytree-hints-landing'));
      }
      familySubMenu.push(this.getRouterLinkItem('Image to Text', 'image-to-text'));
      familySubMenu.push(this.getRouterLinkItem('Photos & Files', libraryRoute.name, libraryRoute.params));
      if (this.userIsLoggedInState && !this.userIsSharedState) {
        familySubMenu.push(
          this.getRouterLinkItem('Clans', 'familytree-clans', {id: familyTreeRoute.params.id}),
          this.getRouterLinkItem('Manage Trees', 'familytree-manage-all'),
          this.getRouterLinkItem('Record Inbox', 'record-inbox')
        );
      }
      familySubMenu.push(this.getRouterLinkItem('My Zupus', 'my-zupus'));
      if (this.userFwsUsernameState) {
        familySubMenu.push(
          this.getRouterLinkItem('My Research Reports', 'jlt-report-index', {username: this.userFwsUsernameState})
        );
      }

      let subscriptionPlans = this.getRouterLinkItem('Plans', 'subscription-plans');
      let moreSubMenu = [...resourcesSubMenu];
      if (!this.userIsSharedState && !this.userIsSubscribedState) {
        moreSubMenu.unshift(subscriptionPlans);
      }
      if (!this.userIsLoggedInState) {
        moreSubMenu.push({
          isButtonRouterLinkItem: true,
          text: 'Get Started for Free',
          route: {name: 'register', query: {redirect: this.redirect}},
        });
      }
      let menu = [
        {text: 'Search', subMenu: searchRecordsSubMenu},
        {text: 'Family Tree', subMenu: familySubMenu, mobileSeText: 'Tree'},
        {text: 'Resources', subMenu: resourcesSubMenu, minScreen: this.$breakpoints.phablet},
        {text: 'More', subMenu: moreSubMenu, maxScreen: this.$breakpoints.phablet},
      ];
      if (!this.userIsSharedState && !this.userIsSubscribedState) {
        menu.push({...subscriptionPlans, minScreen: this.$breakpoints.phablet});
      }
      if (this.userIsLoggedInState) {
        menu.push({
          icon: 'help-icon',
          iconAltText: 'Contact Support',
          route: {name: 'support'},
          separated: true,
          tooltip: {
            content: 'Support',
            triggers: ['hover'],
            placement: 'bottom',
            strategy: 'fixed',
            arrowOverflow: false,
          },
        });
        menu.push(this.userMenu);
      } else {
        menu.push({isLoginItem: true, route: {name: 'login', query: {redirect: this.redirect}}});
        if (this.$route.name !== 'subscription-plans' && this.featureSwitchesState.REGISTER_MENU_BUTTON) {
          menu.push({
            isButtonRouterLinkItem: true,
            minScreen: this.$breakpoints.phablet,
            text: 'Get Started for Free',
            mobileText: 'Start for Free',
            route: {name: 'register', query: {redirect: this.redirect}},
          });
        }
      }
      return menu;
    },
    userMenu() {
      return {
        isUserItem: true,
        subMenu: this.subMenuItems,
      };
    },
    isMobileView() {
      return this.windowWidthState <= this.$breakpoints.mainMenu;
    },
    subMenuItems() {
      const logout = this.getLogoutMenuItem();
      let impersonate_menus = [];

      if (this.impersonatedUserEmailState) {
        const imperson = {text: `Impersonating ${this.impersonatedUserEmailState}`};
        const stop_imperson = {
          text: `Stop Impersonation`,
          clickHandler: () => {
            stopImpersonation(this);
          },
        };

        impersonate_menus = [imperson, stop_imperson];
      }

      if (this.userIsSharedState) {
        return [...impersonate_menus, logout];
      }

      const account = this.getRouterLinkItem('Account', 'user-profile');
      const history = this.getRouterLinkItem('Billing History', 'my-orders');

      return [...impersonate_menus, account, history, logout];
    },
    redirect() {
      const excludeRoutes = ['login', 'forgotpwd', 'register'];
      return excludeRoutes.includes(this.$route.name) ? this.$route.query.redirect : this.$route.fullPath;
    },
  },
  methods: {
    getLogoutMenuItem() {
      return {
        text: 'Logout',
        clickHandler: () => {
          this.$store.dispatch('logoutAction');
          this.$router.push({name: 'login', query: {redirect: this.$route.fullPath}});
          AnalyticsMainHandler.resumeTracking(); // init as impersonation stopped
          AnalyticsMainHandler.logout();
        },
      };
    },
    getRouterLinkItem(text, routerName, routeParams, routeQuery) {
      return {text: text, route: {name: routerName, params: routeParams || {}, query: routeQuery || {}}};
    },
    getExternalLinkItem(text, externalLink, icon) {
      return {text, clickHandler: () => window.open(externalLink, '_blank'), icon};
    },
  },
  components: {MainMenuItem},
};
</script>

<style lang="scss" scoped>
@import '@common/style/menu';
.main_menu {
  &::v-deep .button_link_item {
    margin: 0 32px 0 16px;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    &::v-deep .button_link_item {
      margin: 0 8px 0 8px;
    }
    .main_menu_item.right-side {
      margin-left: auto;
    }
  }
}
</style>
