<template>
  <router-link :to="hintsRoute" class="family-tree-hints-button" :class="{'is-active': hintsCount}">
    <span class="text-sm avenir-font-offset-1">RecordFinder™</span>
    <span class="review-count text-xs" v-if="hintsCount">
      {{ hintsCount }}
    </span>
  </router-link>
</template>

<script>
export default {
  computed: {
    hintsRoute() {
      return {name: 'familytree-hints', params: {id: this.$route.params.id}, query: {tab: 'new'}};
    },
    hintsCount() {
      return this.$store.getters.userHintsTreeIdsListState[this.$route.params.id];
    },
  },
  name: 'familyTreeHintsButton',
};
</script>

<style scoped lang="scss">
.family-tree-hints-button {
  min-height: 26px;

  .review-count {
    border-radius: 100%;
    background: $primary-400;
    color: $white;
    font-weight: 700;
    width: 20px;
    height: 20px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    text-decoration: none;
  }
}
</style>
