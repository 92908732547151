<template>
  <multiselect
    :value="value"
    ref="cemetery-input"
    :placeholder="placeholder"
    :searchable="true"
    :internal-search="false"
    :preserveSearch="false"
    :clearOnSelect="true"
    :show-no-options="true"
    :show-no-results="searchRan"
    :hide-selected="false"
    :options="options"
    track-by="cemetery_id"
    label="name"
    openDirection="bottom"
    :showLabels="false"
    :loading="loading"
    class="bordered"
    @search-change="onSearchChange"
    @select="onSelect"
    @close="clearOptions"
  >
    <template v-slot:caret>
      <div
        class="caret-icon close-icon"
        v-if="value && value.cemetery_id && !isMultiselectOpened()"
        @mousedown.prevent.stop="clear()"
      >
        <close-icon></close-icon>
      </div>
    </template>
    <template v-slot:noResult>
      <span class="input-helper">Not found</span>
    </template>
    <template v-slot:noOptions>
      <span class="input-helper">Start typing to search...</span>
    </template>
    <template v-slot:singleLabel="props">
      <span>{{ props.option.name || placeholder || 'All Cemeteries' }}</span>
    </template>
    <template v-slot:afterList>
      <div class="loading-after-list" v-if="loading">
        <span class="input-helper">Searching...</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import debounce from 'lodash/debounce';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';

export default {
  props: {
    value: Object,
    placeholder: {type: String, default: ''},
  },
  data() {
    return {
      searchRan: false,
      loading: false,
      options: [],
    };
  },
  methods: {
    onSearchChange(query) {
      this.clearOptions();
      if (!query) {
        return;
      }
      this.searchSources(query);
    },
    searchSources: debounce(function (query) {
      this.loading = true;
      this.network.cemetery
        .searchBurialLocation({q: query})
        .then(response => {
          this.options = response.objects;
        })
        .finally(() => {
          this.loading = false;
        });
    }, 200),
    onSelect(value) {
      this.$emit('select', value);
    },
    clear() {
      this.onSelect({});
    },
    isMultiselectOpened() {
      return this.$refs['cemetery-input'] && this.$refs['cemetery-input'].isOpen;
    },
    clearOptions() {
      this.options = [];
    },
  },
  name: 'CemeteryInput',
  components: {Multiselect, CloseIcon},
};
</script>

<style scoped lang="scss"></style>
