<template>
  <div class="saved-record-item">
    <div class="mention-info">
      <div class="title">
        <router-link class="bold-link" :to="personRecordsRoute" v-if="userHasAccess">{{
          record.mention_person.full_name
        }}</router-link
        ><a v-else class="bold-link" @click="handleSubscriptionWall">{{ record.mention_person.full_name }}</a> in
        {{ recordTitle }}
      </div>
      <div class="supplemental info-bar">
        <record-type :type="record.source.mention_type_display" :type-name="subtitle"></record-type>
        <span class="icon-button"><added-icon :size="22" /> {{ addedDate }}</span>
      </div>
    </div>
    <div class="save-info">
      <router-link class="icon-button person-link bold-link" :to="ftPersonRoute" v-if="record.person"
        ><account-icon :size="20" /> <span>{{ record.person.full_name }}</span></router-link
      >
      <a class="icon-button person-link bold-link" v-else @click="saveToFamilyTreeClick"
        ><save-icon :size="20" /> <span>Save to Family Tree</span></a
      >
      <a @click="removeClick" class="icon-button supplemental"><span>Delete</span></a>
    </div>
  </div>
</template>

<script>
import RecordType from '@common/elements/icons/RecordType';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getPersonRecordDetailRoute, getSubscriptionWallRoute} from '@common/utils/utils.routes';
import moment from 'moment';
import AccountIcon from 'vue-material-design-icons/Account';
import SaveIcon from 'vue-material-design-icons/AccountArrowLeft';
import AddedIcon from 'vue-material-design-icons/ClockOutline';

import ConfirmDeleteMention from '@/components/common/modals/ConfirmDeleteMention';
import SaveMentionModalContent from '@/components/common/modals/SaveMentionModalContent';

export default {
  props: {
    record: Object,
  },
  computed: {
    userHasAccess() {
      return this.$store.getters.userIsSubscribedState || this.$store.getters.userIsStaffState;
    },
    recordTitle() {
      return this.record.source.title_en || this.record.source.title_ch;
    },
    subtitle() {
      const country = this.record.source.place_display_text;
      const type = this.record.source.mention_type_display;
      return country && type ? `${country}, ${type}` : country || type;
    },
    personRecordsRoute() {
      return getPersonRecordDetailRoute(this.record.mention_person.code, this.record.mention_person.full_name);
    },
    addedDate() {
      return moment(this.record.updated_at).format('MMM DD, YYYY');
    },
    ftPersonRoute() {
      return {
        name: 'familytree-profile-details',
        params: {id: this.record.person.family_tree_id, personId: this.record.person.object_id},
        query: {tab: 'details'},
      };
    },
  },
  methods: {
    handleSubscriptionWall() {
      const toRoute = getSubscriptionWallRoute(
        this.$router.resolve(this.personRecordsRoute).href,
        this.record.mention_person.full_name
      );
      const fullPath = this.$router.resolve(toRoute).href;
      AnalyticsMainHandler.trackHitPaywallAction('View mention detail', toRoute.name, fullPath, this.$route.name);
      this.$router.push(toRoute);
    },
    removeClick() {
      const props = {
        recordId: this.record.id,
        recordName: this.recordTitle,
        personName: this.record.person ? this.record.person.full_name : 'Inbox',
        reloadRecords: this.reloadRecords,
      };
      const modalParams = {classes: 'clear_modal white_modal', class: 'mobile_bottom'};
      this.$modal.show(ConfirmDeleteMention, props, modalParams);
    },
    saveToFamilyTreeClick() {
      if (!this.userHasAccess) {
        const subPlansRoute = getSubscriptionWallRoute(this.$route.fullPath, null, false);
        const fullPath = this.$router.resolve(subPlansRoute).href;
        AnalyticsMainHandler.trackHitPaywallAction(
          'Save record in record inbox',
          subPlansRoute.name,
          fullPath,
          this.$route.name
        );
        this.$router.push(subPlansRoute);
        return;
      }
      this.$modal.show(
        SaveMentionModalContent,
        {
          savedMentionId: this.record.id,
          callback: this.reloadRecords,
          source: this.record.source,
          matchPersonId: this.record.mention_person.object_id,
        },
        {classes: 'clear_modal white_modal', scrollable: true, height: 'auto'}
      );
    },
    reloadRecords() {
      this.$emit('reload-records');
    },
  },
  components: {RecordType, SaveIcon, AddedIcon, AccountIcon},
  name: 'SavedRecordItem',
};
</script>

<style lang="scss" scoped>
.saved-record-item {
  background-color: $background-light;
  border-radius: 4px;
  padding: 20px;
  margin: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  .supplemental {
    color: $neutral-500;
  }

  .mention-info {
    min-width: 0;
    max-width: 100%;

    .title {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .info-bar {
      margin-top: 10px;
      display: flex;

      .record-type {
        margin-right: 20px;
      }
    }
  }

  .save-info {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }

  .icon-button {
    display: flex;
    align-items: center;
    .material-design-icon {
      margin-right: 5px;
      margin-top: -3px;
      vertical-align: middle;
    }
  }

  .bold-link {
    font-weight: bold;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    padding: 16px;

    .mention-info {
      .title {
        -webkit-line-clamp: 2;
      }
    }

    .save-info {
      margin-top: 20px;
      flex-direction: row-reverse;
    }

    .person-link {
      display: inline;
      text-align: end;
    }

    .info-bar {
      flex-direction: column;
      > * {
        margin-top: 10px;
      }
    }
  }
}
</style>
