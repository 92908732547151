<template>
  <div class="breadcrumbs-item text-sm" @click="$emit('click', person)">
    <img :src="profilePictureAttachment" v-if="profilePictureAttachment" class="profile-picture" />
    <person-icon-colored v-else :gender="person.gender" :size="16"></person-icon-colored>
    <div class="name">{{ displayName }}</div>
  </div>
</template>

<script>
import PersonIconColored from '@/base/icons/person/PersonIconColored';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';

export default {
  props: {
    person: Object,
  },
  computed: {
    displayName() {
      return this.person.full_name || UNKNOWN_NAME;
    },
    profilePictureAttachment() {
      const person = this.$store.getters.familyTreePersonsByIdState[this.person.id];
      if (person && person.profile_picture && person.profile_picture.attachment) {
        return person.profile_picture.attachment;
      }
      return this.person.profile_picture && this.person.profile_picture.attachment;
    },
  },
  components: {PersonIconColored},
  name: 'breadcrumbsItem',
};
</script>

<style scoped lang="scss">
.breadcrumbs-item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:last-of-type {
    font-weight: 500;
  }

  .person-icon-colored,
  .profile-picture {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .name {
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
