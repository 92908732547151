<template>
  <div class="login-container" :class="{'has-splash': showSplash}">
    <splash-image-light v-if="showSplash"></splash-image-light>

    <div class="readable_content account-form-container">
      <h5>{{ title }}</h5>

      <div class="account-form">
        <form class="login-form">
          <email-input :email="email" @input="onEmailChange" :ref="emailInputRef" @enter="_confirm()"></email-input>
          <password-input :password="password" @input="onPasswordChange" @enter="_confirm()"></password-input>
        </form>
        <transition name="fade">
          <p class="error" v-if="errorMessage">
            {{ errorMessage }}
          </p>
        </transition>
        <mcr-button @click="_confirm" :loading="submitting">
          <span>Log In</span>
        </mcr-button>
        <div class="social-divider">
          <span>OR</span>
        </div>
        <div class="social-login-buttons">
          <facebook-sign-in-button-container
            class="social-button"
            :referral-code="$route.query.referral"
            @success="afterSuccessfulLogin"
            @fail="onFailure"
          ></facebook-sign-in-button-container>
          <google-sign-in-button-container
            class="social-button"
            :referral-code="$route.query.referral"
            @success="afterSuccessfulLogin"
            @fail="onFailure"
          ></google-sign-in-button-container>
          <institution-sign-in-button-container
            class="social-button"
            @success="afterSuccessfulAlternativeLogin"
            @fail="onAlternativeFailure"
          ></institution-sign-in-button-container>
          <transition name="fade">
            <p class="error" v-if="alternativeErrorMessage">
              {{ alternativeErrorMessage }}
            </p>
          </transition>
        </div>
      </div>
      <div class="action_links">
        <router-link :to="{name: 'register', query: registerPageQuery}">
          <span @click="trackPreRegisterAction">{{ registerLabel }}</span>
        </router-link>
        <router-link :to="{name: 'forgotpwd', query: {redirect: this.redirectPath}}">Forgot your password?</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import mcrButton from '@common/elements/buttons/mcrButton';
import EmailInput from '@common/elements/inputs/EmailInput.vue';
import PasswordInput from '@common/elements/inputs/passwordInput';
import AnalyticsHubspotHandler from '@common/utils/analytics/analytics.hubspot';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {isEmailValid} from '@common/utils/utils';
import {isValidRedirectPath} from '@common/utils/utils.routes';

import FacebookSignInButtonContainer from '@/base/elements/social-buttons/FacebookSignInButtonContainer';
import GoogleSignInButtonContainer from '@/base/elements/social-buttons/GoogleSignInButtonContainer';
import InstitutionSignInButtonContainer from '@/base/elements/social-buttons/InstitutionSignInButtonContainer';

import NotificationVerifyEmail from '@/components/common/modals/NotificationVerifyEmail';

import SplashImageLight from '@/components/modules/login/SplashImageLight';

export default {
  mounted() {
    this.$store.dispatch('autofocusAction', this.$refs[this.emailInputRef].$refs.input);
  },
  beforeDestroy() {
    this.$store.commit('setLoginPageTextState', null);
  },
  name: 'root-login',
  data() {
    return {
      email: '',
      password: '',
      submitting: false,
      errorMessage: '',
      alternativeErrorMessage: '',
      emailInputRef: 'email-input',
      registerLabel: "Don't have an account? Register",
    };
  },
  computed: {
    redirectPath() {
      return this.$route.query.redirect;
    },
    registerPageQuery() {
      return {redirect: this.redirectPath};
    },
    title() {
      return this.$store.getters.loginPageTextState;
    },
    showSplash() {
      return this.$store.getters.windowWidthState > this.$breakpoints.desktop;
    },
  },
  methods: {
    onEmailChange(event) {
      this.email = event.target.value;
    },
    onPasswordChange(event) {
      this.password = event.target.value;
    },
    _confirm() {
      if (!this.email || this.email.length <= 0 || !isEmailValid(this.email)) {
        return;
      }

      if (this.submitting) {
        return;
      }

      this.submitting = true;

      this.errorMessage = '';

      this.$store
        .dispatch('userLoginAction', {
          email: this.email,
          password: this.password,
        })
        .then(res => {
          this.afterSuccessfulLogin(res);
        })
        .catch(() => {
          this.submitting = false;
        });
    },

    getUserDefinedData() {
      this.$store.dispatch('getNotificationsAction');
      this.$store.dispatch('fetchMyInformationAction').then(response => {
        if (!response.email_verified) {
          this.$notification.show(NotificationVerifyEmail);
        }
        this.$nextTick(() => {
          this.$router.push(this.getNextRoute(response));
        });
      });
    },
    getNextRoute(userData) {
      let allowPaths = ['/subscription-plans', '/familytree', '/person-records'];
      allowPaths = [...allowPaths, ...allowPaths.map(text => text.replace('/', '%2F'))];
      if (this.redirectPath && allowPaths.some(path => this.redirectPath.startsWith(path))) {
        return {path: this.redirectPath};
      }
      if (this.$store.getters.featureSwitchesState.ONBOARDING && !userData.is_onboarded && !userData.is_subscribed) {
        return {name: 'familytree-onboarding-start'};
      }
      return this.redirectPath && isValidRedirectPath(this.redirectPath)
        ? {path: this.redirectPath}
        : {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS}};
    },
    onFailure(error) {
      if (error.response) {
        this.errorMessage = error.response.data;
      }
      this.submitting = false;
    },
    onAlternativeFailure(error) {
      if (error.response) {
        this.alternativeErrorMessage = error.response.data;
      }
      this.submitting = false;
    },
    afterSuccessfulLogin(res) {
      this.errorMessage = '';
      this.alternativeErrorMessage = '';
      if (!res || res._reason) {
        this.errorMessage = res._reason;
        this.submitting = false;
        return;
      }
      this.doLogin(res);
    },
    afterSuccessfulAlternativeLogin(res) {
      this.alternativeErrorMessage = '';
      this.errorMessage = '';
      if (!res || res._reason) {
        this.alternativeErrorMessage = res._reason;
        this.submitting = false;
        return;
      }
      this.doLogin(res);
    },
    doLogin(res) {
      AnalyticsMainHandler.setUserId(res.email);
      AnalyticsHubspotHandler.identify(res.email);
      this.emptyRegisterSurnames();
      this.getUserDefinedData();
    },
    emptyRegisterSurnames() {
      this.$store.commit('setSurnamesRegisterState', []);
    },
    trackPreRegisterAction() {
      AnalyticsMainHandler.trackPreRegisterActionLinkClick(getRoutePageName(this.$route), this.registerLabel);
    },
  },
  components: {
    EmailInput,
    SplashImageLight,
    PasswordInput,
    FacebookSignInButtonContainer,
    GoogleSignInButtonContainer,
    mcrButton,
    InstitutionSignInButtonContainer,
  },
};
</script>

<style scoped lang="scss">
@import './login';
</style>
