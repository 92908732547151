<template>
  <div class="select-from-library-modal">
    <div class="heading">
      <div class="text-lg bold">Select from tree library</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="content">
      <div class="photos image-preview-gallery" v-if="allTreesPhotosState.length">
        <file-preview
          v-for="photo in allTreesPhotosState"
          :key="photo.object_id"
          :src="photo.attachment"
          :filename="photo.filename"
          :show-label="false"
          @click.native="select(photo)"
        ></file-preview>
      </div>
      <mcr-loading-indicator :loading="loading"></mcr-loading-indicator>
      <div class="show-more" v-if="hasMore"><a @click="loadMore">Show More</a></div>
    </div>
  </div>
</template>

<script>
import filePreview from '@common/elements/layouts/file-preview/filePreview';
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      loading: true,
      hasMore: false,
      firstLimit: 8,
      offset: 0,
      limit: 20,
    };
  },
  mounted() {
    this.$store.commit('setAllTreesPhotosState', []);
    this.$store
      .dispatch('fetchAllTreesPhotosAction', {offset: 0, limit: this.firstLimit})
      .then(response => {
        this.hasMore = response.objects.length >= this.firstLimit;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    ...mapGetters(['allTreesPhotosState']),
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    loadMore() {
      this.loading = true;
      this.offset = this.offset === 0 ? this.firstLimit : this.limit;
      this.$store
        .dispatch('fetchAllTreesPhotosAction', {offset: this.offset, limit: this.limit})
        .then(response => {
          this.hasMore = response.objects.length >= this.limit;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select(photo) {
      this.closeModal();
      this.$router.push({name: 'image-to-text-results', params: {assetId: photo.object_id}});
    },
  },
  components: {filePreview, CloseIcon},
  name: 'SelectFromLibraryModal',
};
</script>

<style scoped lang="scss">
.select-from-library-modal {
  .show-more {
    text-align: center;
    margin-top: 16px;
  }

  @media only screen and (min-width: $breakpoint-phablet) {
    width: $breakpoint-phablet;
  }
  @media only screen and (min-width: $breakpoint-tablet) {
    width: 800px;
  }
}
</style>
