<template>
  <div class="tab-content tab-relationships">
    <div class="item-block person-relatives-block" ref="block">
      <div class="header">
        <div class="heading-6">{{ title }}</div>
        <v-dropdown v-if="addAllowed" :shown.sync="isPopoverOpen" :autoHide="true">
          <mcr-button class="white small"><plus-icon :size="20" /><span>Add Relatives</span></mcr-button>
          <div slot="popper" class="popover-content">
            <div v-if="!personHasBothParents" class="option" @click="addParentClick">Parent</div>
            <div class="option" @click="addSpouseClick">Spouse</div>
            <div class="option" @click="addSiblingClick">Sibling</div>
            <div class="option" @click="addChildClick">Child</div>
          </div>
        </v-dropdown>
      </div>
      <div class="body">
        <div
          v-for="item in relativesItems"
          :key="item.label"
          class="content-item"
          :class="{'mobile-gapped': item.mobileGapped}"
        >
          <div class="label text-md text-lg-mobile">{{ item.label }}</div>
          <div class="persons">
            <div v-for="person in item.persons" class="relative-item-wrapper">
              <relative-item
                :person="person"
                :profile-picture="
                  relatives && relatives.profile_pictures ? relatives.profile_pictures[person.object_id] : ''
                "
                :spouse-meta="item.meta[person.object_id] || {}"
                :meta-editable="item.metaEditable"
                :show-add-child="item.type === 'spouses' && addAllowed"
                :show-remove-relationship="item.type !== 'full-siblings' && item.type !== 'half-siblings' && addAllowed"
                :show-change-parents="item.type === 'parents' && addAllowed"
                :children="person.children"
                :key="person.object_id"
                @edit-spouse-meta="openSpouseMetaEditModal"
                @add-child="openAddSpouseChildModal"
                @remove-relationship="onRemoveRelationship($event, item)"
                @change-parents="onChangeParents"
              >
                <template v-slot:empty-state v-if="person.isEmptyState">
                  <div v-if="addAllowed">
                    <a @click="person.action">{{ person.label }}</a>
                  </div>
                  <div v-else>{{ person.label }}</div>
                </template>
              </relative-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import PlusIcon from 'vue-material-design-icons/PlusCircleOutline';
import {mapGetters} from 'vuex';

import RelativeItem from '@/components/common/tree/RelativeItem';

import {
  CHILD_RELATION_TYPE,
  PARENT_RELATION_TYPE,
  SIBLING_RELATION_TYPE,
  SPOUSE_RELATION_TYPE,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import editSpouseMetaModalContent from '@/components/modules/familyTree/tree/modals/editSpouseMetaModalContent';
import {openAddPersonModal} from '@/components/modules/familyTree/tree/services.modals';
import ChangeParentsModalContent from '@/components/modules/familyTreeProfile/components/ChangeParentsModalContent.vue';
import RemoveRelationshipConfirm from '@/components/modules/familyTreeProfile/components/RemoveRelationshipConfirm';

export default {
  data() {
    return {
      isPopoverOpen: false,
    };
  },
  computed: {
    ...mapGetters(['familyTreePersonState', 'familyRelativesByPersonIdState']),
    name() {
      const names = this.familyTreePersonState.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    title() {
      return `${this.name}'s Relatives`;
    },
    personId() {
      return parseInt(this.$route.params.personId);
    },
    addAllowed() {
      return this.familyTreePersonState.is_write_allowed;
    },
    relatives() {
      return this.familyRelativesByPersonIdState[this.personId] || {};
    },
    separateSiblings() {
      const separatedSiblings = {
        fullSiblings: [],
        halfSiblings: [],
      };
      if (!this.relatives.siblings) {
        return separatedSiblings;
      }
      for (let sibling of this.relatives.siblings) {
        let isFullSibling = sibling.parents_ids.length === this.relatives.parents.length;
        this.relatives.parents.map(p => {
          if (!sibling.parents_ids.includes(p.object_id)) {
            isFullSibling = false;
          }
        });
        if (isFullSibling) {
          separatedSiblings.fullSiblings.push(sibling);
        } else {
          separatedSiblings.halfSiblings.push(sibling);
        }
      }
      return separatedSiblings;
    },
    personHasBothParents() {
      return this.relatives && this.relatives.parents && this.relatives.parents.length === 2;
    },
    parents() {
      const parents = this.relatives && this.relatives.parents ? this.relatives.parents : [];
      if (parents.length === 0) {
        return this.addAllowed ? [{isEmptyState: true, label: '+ Add Parent', action: this.addParentClick}] : [];
      }
      if (parents.length === 1) {
        const label =
          parents[0].gender === 'm' ? '+ Add Mother' : parents[0].gender === 'f' ? '+ Add Father' : '+ Add Parent';
        const newParent = {isEmptyState: true, label: label, action: this.addParentClick};
        return this.addAllowed ? [...parents, newParent] : parents;
      }
      return parents;
    },
    children() {
      return this.relatives.children
        ? this.relatives.children.map(child => {
            return {...child, profile_picture: this.relatives.profile_pictures[child.object_id]};
          })
        : [];
    },
    spousesWithChildren() {
      const spouses = this.relatives.spouses
        ? this.relatives.spouses.map(spouse => {
            const children = this.children.filter(ch => ch.parents_ids.includes(spouse.object_id));
            return {...spouse, children};
          })
        : [];
      let noSpouseChildren = this.children.filter(ch => ch.parents_ids.length === 1);
      if (noSpouseChildren && noSpouseChildren.length) {
        const emptySpouse = this.addAllowed
          ? {isEmptyState: true, label: '+ Add New Spouse', action: this.addSpouseClick, children: noSpouseChildren}
          : {isEmptyState: true, label: 'Unknown', children: noSpouseChildren};
        return [...spouses, emptySpouse];
      }
      return spouses;
    },
    relativesItems() {
      return [
        {
          label: 'Parents',
          persons: this.parents,
          meta: {},
          type: 'parents',
          mobileGapped: !this.relatives || !this.relatives.parents || this.relatives.parents.length < 2,
        },
        {label: 'Full Siblings', persons: this.separateSiblings.fullSiblings, meta: {}, type: 'full-siblings'},
        {label: 'Half Siblings', persons: this.separateSiblings.halfSiblings, meta: {}, type: 'half-siblings'},
        {
          label: 'Spouses & Children',
          persons: this.spousesWithChildren,
          meta: this.relatives.spouses_meta,
          metaEditable: this.addAllowed,
          mobileGapped: true,
          type: 'spouses',
        },
      ].filter(item => item.persons && item.persons.length);
    },
  },
  methods: {
    addParentClick() {
      return this.openAddPersonModal(null, null, PARENT_RELATION_TYPE);
    },
    addSpouseClick() {
      return this.openAddPersonModal(null, null, SPOUSE_RELATION_TYPE);
    },
    addSiblingClick() {
      return this.openAddPersonModal(null, null, SIBLING_RELATION_TYPE);
    },
    addChildClick() {
      return this.openAddPersonModal(null, null, CHILD_RELATION_TYPE);
    },
    openAddPersonModal(initialData, metaData, relationType) {
      this.isPopoverOpen = false;
      const modalName = 'add-relative';
      const callback = () => {
        this.$store.dispatch('fetchFamilyTreePersonRelativesAction', this.familyTreePersonState.object_id).then(() => {
          this.$toasted.success('Relative created successfully!');
          this.$modal.hide(modalName);
        });
      };
      openAddPersonModal(
        initialData,
        metaData,
        relationType,
        this.familyTreePersonState,
        this.familyTreePersonState.family_tree_id,
        modalName,
        false,
        false,
        callback,
        this
      );
    },
    openSpouseMetaEditModal({person, meta}) {
      const props = {
        name: person.full_name || UNKNOWN_NAME,
        initialData: meta,
        familyTreeId: this.familyTreePersonState.family_tree_id,
        coupleId: meta.couple_id,
        personId: this.familyTreePersonState.object_id,
        spouseId: person.object_id,
      };
      let modalParams = {
        classes: 'clear_modal white_modal',
        scrollable: true,
        height: 'auto',
        name: 'edit-spouse-meta',
      };
      this.$modal.show(editSpouseMetaModalContent, props, modalParams, {});
    },
    openAddSpouseChildModal({person}) {
      return this.openAddPersonModal(null, {otherParentId: person.object_id}, CHILD_RELATION_TYPE);
    },
    onRemoveRelationship({person, isChild}, relativeItem) {
      const params = {
        treeId: this.familyTreePersonState.family_tree_id,
        personName: this.familyTreePersonState.full_name || UNKNOWN_NAME,
        personId: this.familyTreePersonState.object_id,
        relativeName: person.full_name || UNKNOWN_NAME,
        relativeId: person.object_id,
        type: isChild ? 'children' : relativeItem.type,
      };
      let modalParams = {classes: 'clear_modal white_modal', class: 'mobile_bottom', name: 'remove-relationship'};
      this.$modal.show(RemoveRelationshipConfirm, params, modalParams);
    },
    onChangeParents({person}) {
      const params = {
        person: this.familyTreePersonState,
        parent: person,
        treeId: this.familyTreePersonState.family_tree_id,
      };
      let modalParams = {classes: 'clear_modal white_modal', class: 'mobile_bottom', name: 'change-parents'};
      this.$modal.show(ChangeParentsModalContent, params, modalParams);
    },
  },
  components: {McrButton, PlusIcon, RelativeItem},
  name: 'TabRelationships',
};
</script>

<style lang="scss" scoped>
.tab-relationships {
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    .content-item {
      .label {
        font-weight: 800;
        color: $neutral-500;
        margin-bottom: 8px;
      }

      .persons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
    }
  }

  @media only screen and (max-width: $breakpoint-phablet) {
    .body {
      padding: 16px 0;
      row-gap: 20px;
      .content-item {
        .label {
          margin: 0 16px 4px;
        }
      }
      .content-item:not(.mobile-gapped) {
        .persons {
          display: block;

          .relative-item-wrapper:not(:last-child) {
            .relative-item {
              border-bottom: none;
            }
          }
        }
      }

      .content-item.mobile-gapped {
        .persons {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
</style>
