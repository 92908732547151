<template>
  <div class="quick-view add-relative" @mousedown.left.stop v-if="showAddRelativeSubmenu">
    <div class="heading">
      <div class="text-lg bold title">Add a relative of {{ primaryFirstName }}</div>
      <close-icon :size="22" @click="$emit('close')"></close-icon>
    </div>
    <div class="content" v-if="relativesLoading">
      <div class="sub-action loading-message">Fetching relatives...</div>
    </div>
    <div class="content" v-else>
      <mcr-button class="white" :class="{disabled: !canAddParents}" @click="clickAddParent()"
        >Parent
        <div class="hotkey"><key-cup class="dark" :size="14">1</key-cup></div></mcr-button
      >
      <mcr-button class="white" @click="clickAddSpouse()"
        >Spouse
        <div class="hotkey"><key-cup class="dark" :size="14">2</key-cup></div></mcr-button
      >
      <mcr-button class="white" @click="clickAddSibling()"
        >Sibling
        <div class="hotkey"><key-cup class="dark" :size="14">3</key-cup></div></mcr-button
      >
      <mcr-button class="white" @click="clickAddChild()"
        >Child
        <div class="hotkey"><key-cup class="dark" :size="14">4</key-cup></div></mcr-button
      >
    </div>
  </div>
  <div class="quick-view" @mousedown.left.stop v-else>
    <drag-drop-prompt v-if="isEditAllowed" @drop="handleDrop"></drag-drop-prompt>
    <div class="heading">
      <div class="person-data">
        <div class="picture-container">
          <img
            :src="profilePictureAttachment"
            v-if="profilePictureAttachment"
            class="profile-picture"
            @click="profilePictureClick"
          />
          <div class="loading-profile-picture" v-else-if="showProfilePictureLoading"></div>
          <person-icon-colored v-else :gender="person.gender" @click.native="personIconClick"></person-icon-colored>
        </div>
        <div class="name-container">
          <div class="heading-6" v-if="personPrimaryFullnameEn">{{ personPrimaryFullnameEn }}</div>
          <div class="heading-6" v-if="personPrimaryFullnameCn">{{ personPrimaryFullnameCn }}</div>
          <div class="heading-6" v-if="!personPrimaryFullnameEn && !personPrimaryFullnameCn">
            {{ unknownName }}
          </div>
          <div class="strapline text-md" v-if="livingStatus">{{ livingStatus }}</div>
        </div>
        <div class="birth-order" v-if="birthOrder">
          <mcr-wiki-glossary-link :is-help-text="true" naturalId="sibling-order" :text="`#${birthOrder}`" />
        </div>
      </div>
      <close-icon :size="22" @click="$emit('close')"></close-icon>
    </div>
    <div class="hints-action" @click="clickShowHints()" v-if="hintsCount">
      <div class="hints-icon"><img :src="$getAsset('/assets/mcr_logo_dude_transparent.png')" /></div>
      <div class="bold">{{ hintsLabel }}</div>
      <div class="hotkey"><key-cup class="dark" :size="14">H</key-cup></div>
    </div>
    <div class="media">
      <person-photos-container
        ref="photos"
        :add-allowed="isEditAllowed"
        :person-id="person.object_id"
        :person-name-en="personPrimaryFullnameEn"
        :person-name-cn="personPrimaryFullnameCn"
        :explicit-loading="showExplicitPhotosLoading"
        :display-photos-count-desktop="4"
        :display-photos-count-mobile="4"
        :allow-view-all-link="false"
        @files-selected="filesSelected"
        @file-uploaded="fileUploaded"
      >
        <div class="text-md no-margin title" slot="title"><span @click="clickMedia">Photos</span></div>
      </person-photos-container>
    </div>
    <div class="actions">
      <div class="action" @click="clickFullProfile()" v-tooltip="fullProfileTooltip">
        <profile-icon :size="28" title="Full Profile"></profile-icon>
        <div class="text-sm">Full Profile</div>
      </div>
      <div class="action" v-if="isEditAllowed" @click="toggleAddRelativeSubmenu" v-tooltip="addRelativeTooltip">
        <plus-icon :size="28" title="Add Relative"></plus-icon>
        <div class="text-sm">Add Relative</div>
      </div>
      <div class="action" v-if="isEditAllowed" @click="clickQuickEdit" v-tooltip="{content: 'Quick Edit (E)'}">
        <pencil-icon :size="28" title="Quick Edit"></pencil-icon>
        <div class="text-sm">Quick Edit</div>
      </div>
      <div
        class="action"
        :class="{disabled: !searchByProfileEnabled}"
        @click="clickSearchByProfile"
        v-tooltip="{content: 'Search (S)'}"
      >
        <magnify-icon :size="28" title="Media"></magnify-icon>
        <div class="text-sm">Search</div>
      </div>
      <v-dropdown :auto-hide="true" :distance="20" :popper-triggers="['click']" class="more-actions-item">
        <div class="action" v-tooltip="{content: 'More Options'}">
          <more-icon :size="28" title="More Options"></more-icon>
          <div class="text-sm">More Options</div>
        </div>
        <template v-slot:popper>
          <quick-view-menu-sub-action @click.native="clickViewTree"
            ><tree-icon :size="22" title="View Tree"></tree-icon>View Tree
            <div class="hotkey"><key-cup class="dark" :size="14">T</key-cup></div></quick-view-menu-sub-action
          >
          <quick-view-menu-sub-action @click.native="clickViewLineage"
            ><lineage-icon :size="22" title="View Lineage"></lineage-icon>View Lineage
            <div class="hotkey"><key-cup class="dark" :size="14">L</key-cup></div></quick-view-menu-sub-action
          >
          <quick-view-menu-sub-action @click.native="clickViewRelationship"
            ><view-relationship-icon :size="22" title="View Relationship"></view-relationship-icon>View Relationship
            <div class="hotkey"><key-cup class="dark" :size="14">Q</key-cup></div></quick-view-menu-sub-action
          >
          <quick-view-menu-sub-action @click.native="clickDelete" v-if="removable"
            ><delete-icon :size="22" title="Delete"></delete-icon>Delete
            <div class="hotkey"><key-cup class="dark" :size="14">DEL</key-cup></div></quick-view-menu-sub-action
          >
        </template>
      </v-dropdown>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import McrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import KeyCup from '@common/elements/icons/KeyCup';
import DragDropPrompt from '@common/elements/layouts/DragDropPrompt';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getLifetimeYears} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import ProfileIcon from 'vue-material-design-icons/Account';
import CloseIcon from 'vue-material-design-icons/Close';
import DeleteIcon from 'vue-material-design-icons/Delete';
import MoreIcon from 'vue-material-design-icons/DotsVertical';
import LineageIcon from 'vue-material-design-icons/FormatListBulletedSquare';
import MagnifyIcon from 'vue-material-design-icons/Magnify';
import PencilIcon from 'vue-material-design-icons/Pencil';
import PlusIcon from 'vue-material-design-icons/Plus';
import TreeIcon from 'vue-material-design-icons/Sitemap';
import ViewRelationshipIcon from 'vue-material-design-icons/TransitConnectionVariant';

import PersonIconColored from '@/base/icons/person/PersonIconColored.vue';

import {
  CARD_ADD_RELATIVE_HELPER_KEY,
  CARD_FULL_PROFILE_HELPER_KEY,
  UNKNOWN_NAME,
} from '@/components/modules/familyTree/constants';
import PersonPhotosContainer from '@/components/modules/familyTree/tree/modals/personPhotosContainer.vue';
import QuickViewMenuSubAction from '@/components/modules/familyTree/tree/modals/quick/QuickViewMenuSubAction.vue';

import personQuickViewMixin from './personQuickViewMixin';

export default {
  mixins: [personQuickViewMixin],
  props: {
    person: Object,
    isEditAllowed: Boolean,
    removable: Boolean,
    canAddParents: Boolean,
    relativesLoading: Boolean,
    hintsCount: {type: Number, required: false, default: 0},
    searchByProfileEnabled: {type: Boolean},
    // the div is destroyed with a delay, make sure it is not active after close before DOM cleanup happens
    isActive: Boolean,
    birthOrder: {Number, optional: true},
  },
  created() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  watch: {
    isActive(value) {
      if (!value) {
        this.showAddRelativeSubmenu = false;
      }
    },
  },
  data() {
    return {
      showAddRelativeSubmenu: false,
      keyP: 80,
      keyE: 69,
      keyT: 84,
      keyL: 76,
      keyR: 82,
      keyH: 72,
      keyM: 77,
      keyS: 83,
      keyF: 70,
      key1: 49,
      key2: 50,
      key3: 51,
      key4: 52,
      keyDel: 46,
      keyBackspace: 8,
      keyEsc: 27,
      keyQ: 81,
    };
  },
  computed: {
    profilePicture() {
      return this.person && this.person.profile_picture;
    },
    profilePictureAttachment() {
      return this.profilePicture && this.profilePicture.attachment;
    },
    primaryFirstName() {
      const names = this.person.first_names;
      return names && names[0] && names[0].value ? names[0].value : UNKNOWN_NAME;
    },
    personPrimaryFullnameEn() {
      return getFullNameEn(this.person.first_names || [], this.person.surnames || []);
    },
    personPrimaryFullnameCn() {
      return getFullNameCn(this.person.first_names || [], this.person.surnames || []);
    },
    unknownName() {
      return UNKNOWN_NAME;
    },
    livingStatus() {
      return getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
    },
    showExplicitPhotosLoading() {
      return Boolean(this.profilePictureAttachment);
    },
    disableHotkeys() {
      return Boolean(this.$store.getters.galleryDisplayItemState) || !this.isActive;
    },
    hintsLabel() {
      return `Review ${this.hintsCount} Record Match${this.hintsCount > 1 ? 'es' : ''}`;
    },
    fullProfileTooltip() {
      const autoTooltipShown = this.$store.getters.showCardFullProfileTooltipTooltip;
      if (autoTooltipShown) {
        return {
          popperClass: 'text-center bounce',
          theme: 'tooltip-onboarding',
          placement: 'top',
          flip: false,
          triggers: [],
          container: false,
          shown: autoTooltipShown,
          skidding: -2,
          delay: {show: 200},
          content: 'Click for more details',
        };
      }
      return {placement: 'top', content: 'Full Profile (P)'};
    },
    addRelativeTooltip() {
      const autoTooltipShown = this.$store.getters.showCardAddRelativeTooltipState;
      if (autoTooltipShown) {
        return {
          popperClass: 'text-center bounce',
          theme: 'tooltip-onboarding',
          placement: 'top',
          flip: false,
          triggers: [],
          container: false,
          shown: autoTooltipShown,
          skidding: -2,
          delay: {show: 200},
          content: 'Add a relative',
        };
      }
      return {placement: 'top', content: 'Add Relative (R)'};
    },
  },
  methods: {
    areHotkeyDisabled() {
      if (this.disableHotkeys) {
        return true;
      }
      /* hotkeys are disabled when other modal is opened on top of a quick view, e.g. delete confirmation */
      const el = document.getElementById('modals-container');
      return Boolean(el && el.children && el.children.length);
    },
    onKeyDown(e) {
      if (this.areHotkeyDisabled()) {
        return;
      }
      console.log(e.which);
      if (e.which === this.keyP) {
        this.clickFullProfile(true);
      }
      if (e.which === this.keyE && this.isEditAllowed) {
        this.clickQuickEdit(true);
      }
      if (e.which === this.keyR && this.isEditAllowed) {
        this.toggleAddRelativeSubmenu(true);
      }
      if (e.which === this.keyH && this.hintsCount) {
        this.clickShowHints(true);
      }
      if (e.which === this.keyM) {
        this.clickMedia(true);
      }
      if (e.which === this.keyS) {
        this.clickSearchByProfile(true);
      }
      if (e.which === this.key1 && this.isEditAllowed) {
        this.clickAddParent(true);
      }
      if (e.which === this.key2 && this.isEditAllowed) {
        this.clickAddSpouse(true);
      }
      if (e.which === this.key3 && this.isEditAllowed) {
        this.clickAddSibling(true);
      }
      if (e.which === this.key4 && this.isEditAllowed) {
        this.clickAddChild(true);
      }
      if (e.which === this.keyT) {
        this.clickViewTree(true);
      }
      if (e.which === this.keyL) {
        this.clickViewLineage(true);
      }
      if (e.which === this.keyQ) {
        this.clickViewRelationship(true);
      }
      if ((e.which === this.keyDel || e.which === this.keyBackspace) && this.removable) {
        this.clickDelete(true);
      }
      if (e.which === this.keyEsc) {
        this.$emit('close');
      }
    },
    clickFullProfile(hotkey) {
      this.trackClick('Full Profile', hotkey);
      this.$emit('click-full-profile');
    },
    clickQuickEdit(hotkey) {
      this.trackClick('Quick Edit', hotkey);
      this.$emit('click-quick-edit');
    },
    toggleAddRelativeSubmenu(hotkey) {
      this.$store.commit('setShowCardAddRelativeTooltipState', false);
      this.$store.commit('addReviewedItemState', CARD_ADD_RELATIVE_HELPER_KEY);
      if (!this.$store.getters.reviewedItemsState.includes(CARD_FULL_PROFILE_HELPER_KEY)) {
        this.$store.commit('setShowCardFullProfileTooltipState', true);
      }
      this.trackClick('Add Relative', hotkey);
      this.showAddRelativeSubmenu = !this.showAddRelativeSubmenu;
    },
    clickMedia(hotkey) {
      this.trackClick('Media', hotkey);
      this.$emit('click-media');
    },
    clickSearchByProfile(hotkey) {
      this.trackClick('Search', hotkey);
      this.$emit('click-search-by-profile');
    },
    clickViewTree(hotkey) {
      this.trackClick('View Tree', hotkey);
      this.$emit('click-view-tree');
    },
    clickViewLineage(hotkey) {
      this.$emit('close');
      setTimeout(() => {
        this.trackClick('View Lineage', hotkey);
        this.$emit('click-view-lineage');
      }, 0);
    },
    clickViewRelationship(hotkey) {
      this.$emit('close');
      setTimeout(() => {
        this.trackClick('View Relationship', hotkey);
        this.$emit('click-view-relationship');
      }, 0);
    },
    clickDelete(hotkey) {
      this.trackClick('Delete', hotkey);
      this.$emit('click-delete');
    },
    clickShowHints(hotkey) {
      this.trackClick('Review Hints', hotkey);
      this.$emit('click-show-hints');
    },
    clickAddParent(hotkey) {
      if (!this.canAddParents) {
        return this.$toasted.error(`Could not add parent because ${this.primaryFirstName} already has two parents`);
      }
      this.trackClick('Parent', hotkey);
      this.$emit('click-add-parent');
    },
    clickAddSpouse(hotkey) {
      this.trackClick('Spouse', hotkey);
      this.$emit('click-add-spouse');
    },
    clickAddSibling(hotkey) {
      this.trackClick('Sibling', hotkey);
      this.$emit('click-add-sibling');
    },
    clickAddChild(hotkey) {
      this.trackClick('Child', hotkey);
      this.$emit('click-add-child');
    },
    trackClick(label, hotkey) {
      AnalyticsMainHandler.trackTreeQuickMenuActionClickEvent(label, hotkey);
    },
    handleDrop(files) {
      this.$refs.photos.filesSelected(Array.from(files));
    },
  },
  components: {
    McrWikiGlossaryLink,
    DragDropPrompt,
    KeyCup,
    QuickViewMenuSubAction,
    LineageIcon,
    DeleteIcon,
    PersonPhotosContainer,
    PersonIconColored,
    CloseIcon,
    ProfileIcon,
    PlusIcon,
    PencilIcon,
    MagnifyIcon,
    MoreIcon,
    TreeIcon,
    ViewRelationshipIcon,
    McrButton,
  },
  name: 'QuickViewMenu',
};
</script>

<style scoped lang="scss">
.quick-view {
  min-width: 352px;
  position: relative;

  .heading {
    padding: 16px 12px 16px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $neutral-200;

    .person-data {
      display: flex;
    }

    .picture-container {
      width: 56px;
      height: 56px;
      flex-shrink: 0;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      &::v-deep svg {
        width: 40px;
        height: 40px;
      }
    }
    .loading-profile-picture {
      width: 100%;
      height: 100%;
      background-color: $photo-border-color;
      border-radius: 4px;
      margin-bottom: 5px;
    }
    .name-container {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      word-break: break-word;
      max-width: 350px;
      h4 {
        margin: 0;
      }
    }
    .birth-order {
      margin-left: 8px;
      margin-top: 2px;
      color: $neutral-600;
    }
    .strapline {
      color: $neutral-500;
    }

    .close-icon {
      color: $neutral-400;
      cursor: pointer;
    }
  }
  .hints-action {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    position: relative;
    color: $mcr-red;
    border-bottom: 1px solid $neutral-200;
    cursor: pointer;

    .hints-icon {
      background: $power-red;
      border-radius: 50%;
      margin-right: 16px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        margin-left: 1px;
        height: 23px;
      }
    }
    .hotkey {
      margin-left: auto;
      color: $neutral-600;

      .keycup {
        margin: 0;
      }
    }

    &:hover {
      background: $primary-50;
      color: $primary-600;
    }
  }
  .media {
    .title {
      color: $neutral-600;
    }
    .person-photos-container {
      padding: 16px 20px;
      border-bottom: 1px solid $neutral-200;
    }

    .person-photos-container::v-deep {
      .image-preview-gallery {
        margin-top: 8px;
      }
      .title span {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .actions {
    padding: 8px 16px;
    display: flex;
    .action {
      padding: 0 10px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      cursor: pointer;
      color: $neutral-600;
      border-radius: 4px;
      .text-sm {
        white-space: nowrap;
        color: $neutral-500;
        margin-top: 2px;
      }
      &:hover {
        color: $primary-600;
        background: $primary-50;
        .text-sm {
          color: $primary-600;
        }
      }
      &.disabled,
      &.disabled:hover {
        color: $neutral-300;
        background: transparent;
        cursor: not-allowed;
        .text-sm {
          color: $neutral-300;
        }
      }
    }
    .more-actions-item {
      flex: 1;
      display: flex;
    }
  }

  &.add-relative {
    .heading {
      display: flex;
      align-items: center;
      .title {
        color: $neutral-600;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 24px 20px;

      .mcr-button {
        position: relative;
      }

      .hotkey {
        position: absolute;
        right: 16px;
        top: 8px;
      }

      .loading-message {
        font-style: italic;
      }
    }
  }
}
</style>
