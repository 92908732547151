<template>
  <div class="source-details-info">
    <h5>Source Details</h5>
    <close-button @click="closeDetails" />
    <slot name="actions"></slot>

    <div class="sub-heading">Title</div>
    <div class="title">{{ source.title_en }}</div>
    <div class="title">{{ source.title_ch }}</div>
    <div class="title">{{ source.title_pinyin }}</div>

    <template v-if="source.associations.length">
      <div class="sub-heading">Association</div>
      <div v-for="association in source.associations">{{ association.name_en }}</div>
    </template>
    <template v-if="source.cemeteries.length">
      <div class="sub-heading">Cemetery</div>
      <template v-for="cemetery in source.cemeteries">
        <router-link v-if="isCemetery" :to="getCemeteryRoute(cemetery)" :key="cemetery.object_id">{{
          cemetery.name
        }}</router-link>
        <div v-else :key="cemetery.object_id">{{ cemetery.name }}</div>
      </template>
    </template>

    <template v-if="source.clans && source.clans.length">
      <div class="sub-heading">Surname</div>
      <div><link-surname-page-family :clans="source.clans" :show-family="false"></link-surname-page-family></div>
    </template>

    <template v-if="source.ancestral_places && source.ancestral_places.length">
      <div class="sub-heading">Ancestral Place</div>
      <div v-for="place in source.ancestral_places" :key="place.id">
        <link-place-page :place="place" name-field="display_text"></link-place-page>
      </div>
    </template>

    <template v-if="source.place">
      <div class="sub-heading">{{ placeLabel }}</div>
      <div>
        <link-place-page :place="source.place" name-field="display_text"></link-place-page>
      </div>
    </template>
    <template v-if="source.publication_year || (source.time_period_start && source.time_period_end)">
      <div class="sub-heading">Publication Year</div>
      <div v-if="source.publication_year">{{ source.publication_year }}</div>
      <div v-else>{{ source.time_period_start }} - {{ source.time_period_end }}</div>
    </template>
    <router-link v-if="!isCemetery" :to="detailsRoute" class="full-details-link"
      ><details-icon :size="22" /> <span>View Full Details</span></router-link
    >
    <slot name="extra"></slot>
  </div>
</template>

<script>
import CloseButton from '@common/elements/buttons/closeButton';
import linkPlacePage from '@common/elements/links/linkPlacePage';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {sourceTypes} from '@common/utils/consts.source';
import {getCemeteryDetailRoute} from '@common/utils/utils.routes';
import {getPlaceLabelBySourceType} from '@common/utils/utils.sources';
import DetailsIcon from 'vue-material-design-icons/ClipboardText';

export default {
  props: {
    source: Object,
    detailsRoute: Object,
  },
  computed: {
    isCemetery() {
      return this.source.type === sourceTypes.CEMETERY;
    },
    placeLabel() {
      return getPlaceLabelBySourceType(this.source.type);
    },
  },
  methods: {
    closeDetails() {
      this.$emit('closeDetails');
    },
    getCemeteryRoute(cemetery) {
      return getCemeteryDetailRoute(cemetery.object_id, cemetery.name);
    },
  },
  components: {CloseButton, DetailsIcon, linkSurnamePageFamily, linkPlacePage},
};
</script>

<style lang="scss" scoped>
.source-details-info {
  position: relative;
  &::v-deep {
    .mcr-button {
      margin-bottom: 16px;
    }
  }
}
.sub-heading {
  color: $text-alternate-color;
  margin-top: 16px;
}

.title {
  word-break: break-word;
}

.full-details-link {
  display: flex;
  align-items: center;
  border-top: 1px solid $divider-line-light;
  border-bottom: 1px solid $divider-line-light;
  margin-top: 24px;
  padding: 24px 0;
  .material-design-icon {
    margin-right: 7px;
    position: relative;
    top: -1px;
  }
}
</style>
