import router from '@/router';
import network from '@common/network/network';
import {getRouteNameByUrl} from '@common/utils/utils.routes';

export default {
  userLoginAction(context, {email, password}) {
    return new Promise((resolve, reject) => {
      context.dispatch('loginCleanupAction');
      context.commit(
        'setNextRefreshTimeState',
        new Date().getTime() + parseInt(process.env.VUE_APP_REFRESH_TOKEN_TIMEOUT)
      );
      const surnames = context.getters.surnamesRegisterState;
      network.member.login({email, password, surnames}).then(response => {
        // even if login fails, the endpoint returns 200, but has error details in `_reason`
        if (response && (!response._reason || response._reason.length === 0)) {
          context.commit('setUserUsernameState', response.username);
          context.commit('setUserKeyState', response.key);
          context.commit('setClanPedigreeDetailsState', null);
          context.commit('setPersonMentionsState', null);
          context.commit('setFamilyTreeLineageState', null);
          context.commit('setUserFamilyTreeIdState', response.family_tree_id);
        }
        resolve(response);
      });
    });
  },
  userIpAddressLoginAction(context) {
    return new Promise((resolve, reject) => {
      network.member.ipAddressLogin().then(response => {
        // even if login fails, the endpoint returns 200, but has error details in `_reason`
        if (response && (!response._reason || response._reason.length === 0)) {
          context.commit('setUserUsernameState', response.username);
          context.commit('setUserKeyState', response.key);
          context.commit('setClanPedigreeDetailsState', null);
          context.commit('setPersonMentionsState', null);
        }
        resolve(response);
      });
    });
  },
  fetchMyInformationAction(context) {
    return new Promise((resolve, reject) => {
      network.member
        .myInformation()
        .then(response => {
          context.commit('setResponseUserState', response);
          context.commit('setUserFamilyTreeIdState', response.family_tree_id);
          resolve(response);
        })
        .catch(error => {
          context.commit('clearUserState');
          reject(error);
        });
    });
  },
  refreshMyInformationAction(context) {
    return new Promise((resolve, reject) => {
      network.member
        .refresh()
        .then(response => {
          context.commit('setResponseUserState', response);
          context.commit('setUserFamilyTreeIdState', response.family_tree_id);
          context.commit('setImpersonatedUserFamilyTreeIdState', response.impersonate_family_tree_id);
          resolve(response);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            context.commit('clearUserState');
          }
          reject(error);
        });
    });
  },
  changeUserInformationAction(context, {userId, ...data}) {
    return new Promise((resolve, reject) => {
      context.commit('setFamilyTreePreferencesKeyState', data);
      network.member
        .changeUserInformation({id: userId, ...data})
        .then(resolve)
        .catch(reject);
    });
  },
  googleSignInAction(context, {accessToken, referralCode}) {
    return new Promise((resolve, reject) => {
      context.dispatch('loginCleanupAction');
      const surnames = context.getters.surnamesRegisterState;
      const entry_point_url = context.getters.fullPathBeforeLoginRegisterState;
      const entry_point_name = getRouteNameByUrl(entry_point_url, router);
      network.member
        .googleOauth2({
          access_token: accessToken,
          entry_point_url,
          entry_point_name,
          surnames,
          referral_code: referralCode,
        })
        .then(response => {
          context.commit('setUserUsernameState', response.username);
          context.commit('setUserKeyState', response.key);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  googleConnectAction(context, accessToken) {
    return new Promise((resolve, reject) => {
      network.member
        .googleOauth2Connect({access_token: accessToken})
        .then(response => {
          context.commit('setGoogleConnectErrorState', null);
          resolve(response);
        })
        .catch(error => {
          context.commit('setGoogleConnectErrorState', error.response.data);
        });
    });
  },
  facebookSignInAction(context, {accessToken, referralCode}) {
    return new Promise((resolve, reject) => {
      context.dispatch('loginCleanupAction');
      const surnames = context.getters.surnamesRegisterState;
      const entry_point_url = context.getters.fullPathBeforeLoginRegisterState;
      const entry_point_name = getRouteNameByUrl(entry_point_url, router);
      network.member
        .facebookOauth2({
          access_token: accessToken,
          entry_point_url,
          entry_point_name,
          surnames,
          referral_code: referralCode,
        })
        .then(response => {
          context.commit('setUserUsernameState', response.username);
          context.commit('setUserKeyState', response.key);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  facebookConnectAction(context, accessToken) {
    return new Promise((resolve, reject) => {
      network.member
        .facebookOauth2Connect({access_token: accessToken})
        .then(response => {
          context.commit('setFacebookConnectErrorState', null);
          resolve(response);
        })
        .catch(error => {
          context.commit('setFacebookConnectErrorState', error.response.data);
        });
    });
  },
  getTutorialsShownAction(context) {
    return new Promise((resolve, reject) => {
      network.member
        .getTutorialsShown()
        .then(data => {
          context.commit('setTutorialsShownState', data.tutorials_shown);
          resolve(data.tutorials_shown);
        })
        .catch(reject);
    });
  },
  updateTutorialsShownAction(context, {tutorial_id}) {
    return new Promise((resolve, reject) => {
      network.member
        .updateTutorialsShown({tutorial_id})
        .then(data => {
          context.commit('setTutorialsShownState', data.tutorials_shown);
          resolve(data.tutorials_shown);
        })
        .catch(reject);
    });
  },
  getSearchHintsBannerAction(context) {
    network.member
      .bannerRecordMatching()
      .then(response => {
        context.commit('setSearchHintsBannerDisplayState', response.show_banner);
        context.commit('setSearchHintsBannerTutorialIdState', response.tutorial_id);
      })
      .catch(() => {
        context.commit('setSearchHintsBannerDisplayState', false);
        context.commit('setSearchHintsBannerTutorialIdState', '');
      });
  },
};
