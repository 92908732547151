<template>
  <modal :name="modalName" classes="clear_modal white_modal" @before-open="beforeOpen" :scrollable="true" height="auto">
    <div class="body">
      <div class="selector-wrapper">
        <div class="select-type">
          <p>
            <input
              id="new-radio"
              type="radio"
              :value="RELATION_SOURCES.NEW_ON_RECORD"
              v-model="selectedRelationSource"
            />
            <label for="new-radio">Create Relative and Add to this Record</label>
          </p>
          <p>
            <input
              id="existing-radio"
              type="radio"
              :value="RELATION_SOURCES.EXISTING"
              v-model="selectedRelationSource"
            />
            <label for="existing-radio">Set Existing Person as Relative without Adding to Record</label>
          </p>
        </div>
        <multiselect
          class="relation-multiselect bordered"
          v-model="selectedRelationType"
          :options="RELATION_TYPES"
          :allow-empty="false"
          :showLabels="false"
          placeholder="Relation Type"
        ></multiselect>
      </div>

      <div class="form" v-if="selectedRelationSource === RELATION_SOURCES.EXISTING">
        <add-relative-existing-person-form ref="form-existing"></add-relative-existing-person-form>
        <div class="error" v-if="error">{{ error }}</div>
        <mcr-button class="submit-button" :loading="relationLoading" @click="createRelationFromExisting"
          >Submit</mcr-button
        >
      </div>
      <div v-else>
        <add-relative-new-on-record-form
          :form-loading="createLoading || relationLoading"
          @save="createPersonAndRelation"
          @cancel="closeModal"
        ></add-relative-new-on-record-form>
        <div class="error" v-if="error">{{ error }}</div>
      </div>
    </div>
  </modal>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import isEmpty from 'lodash/isEmpty';
import Multiselect from 'vue-multiselect';

import AddRelativeExistingPersonForm from './AddRelativeExistingPersonForm';
import AddRelativeNewOnRecordForm from './AddRelativeNewOnRecordForm';

const RELATION_SOURCES = {
  EXISTING: 'existing',
  NEW_ON_RECORD: 'new_on_record',
};
const RELATION_TYPES = {
  PARENT: 'Parent',
  CHILD: 'Child',
  SPOUSE: 'Spouse',
};

export default {
  props: {
    personId: [Number, String],
    sourceId: String,
    imageIds: Array,
  },
  data() {
    return {
      modalName: `add-relative-modal-${this.sourceId}`,
      RELATION_SOURCES,
      RELATION_TYPES: Object.values(RELATION_TYPES),
      selectedRelationSource: RELATION_SOURCES.NEW_ON_RECORD,
      selectedRelationType: RELATION_TYPES.SPOUSE,
      error: '',
      relationLoading: false,
      createLoading: false,
    };
  },
  methods: {
    beforeOpen() {
      if (isEmpty(this.$store.getters.familyTreePersonOptionsState)) {
        this.$store.dispatch('fetchFamilyTreePersonOptionsAction');
      }
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
    createRelationFromExisting() {
      this.createRelation(this.$refs['form-existing'].relativeId);
    },
    createRelation(relativeId) {
      if (!relativeId) {
        this.error = 'Relative ID is required';
        return;
      }
      this.relationLoading = true;

      const methodByRelationType = {
        [RELATION_TYPES.PARENT]: this.createParentRelation,
        [RELATION_TYPES.CHILD]: this.createChildRelation,
        [RELATION_TYPES.SPOUSE]: this.createSpouseRelation,
      };
      methodByRelationType[this.selectedRelationType](relativeId, parseInt(this.personId))
        .then(() => {
          this.$emit('update');
          this.closeModal();
        })
        .catch(error => {
          this.error = error.error ? error.error : error;
        })
        .finally(() => {
          this.relationLoading = false;
        });
    },
    createParentRelation(parentId, childId) {
      return this.$store.dispatch('createParenthoodAction', {parent_ids: [parentId], child_ids: [childId]});
    },
    createChildRelation(childId, parentId) {
      return this.createParentRelation(parentId, childId);
    },
    createSpouseRelation(person1Id, person2Id) {
      return this.$store.dispatch('createCoupleAction', {person1_id: person1Id, person2_id: person2Id});
    },
    createPersonAndRelation(data) {
      this.createLoading = true;
      this.$store
        .dispatch('createFamilyTreePersonAction', data.person)
        .then(response => {
          const relativeId = response.object_id;
          const mentionParams = {personId: relativeId, imageIds: this.imageIds, sourceId: this.sourceId};
          this.$store.dispatch('createMentionAction', mentionParams);
          this.createRelation(relativeId);
        })
        .catch(error => {
          this.error = error.error ? error.error : error;
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
  },
  components: {AddRelativeNewOnRecordForm, AddRelativeExistingPersonForm, Multiselect, McrButton},
  name: 'AddRelativeModal',
};
</script>

<style lang="scss" scoped>
.relation-multiselect {
  margin: 20px 0;
}
.submit-button {
  margin-top: 16px;
}
.form {
  padding: 0 16px 16px;
}
.selector-wrapper {
  padding: 16px 16px 0;
}
.add-relative-new-on-record-form {
  border-top: 1px solid $neutral-200;
}
</style>
