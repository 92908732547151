<template>
  <div class="person-hint-item match-item result-item" :class="{'is-row': isDesktop}">
    <div class="person-hint-content">
      <slot name="tag"></slot>

      <div class="badge-container" v-if="hint.match_person.has_relatives || hint.is_high_quality">
        <div class="chip dark-green-reverse bolder slim text-md" v-if="hint.is_high_quality">
          <workspace-premium-icon :size="20" />high quality
        </div>
        <div class="chip dark-green-reverse bolder slim text-md" v-if="hint.match_person.has_relatives">
          New relatives found
        </div>
      </div>

      <div class="source-name title" v-if="dataClickable">
        <router-link :to="matchRoute" v-if="dataVisible" class="details-link">{{ sourceTitle }}</router-link>
        <a v-else class="details-link" @click="openSubscriptionWallModal">{{ sourceTitle }}</a>
      </div>
      <div v-else class="source-name title">
        {{ sourceTitle }}
      </div>
      <record-type :type="hint.source.type_display" :type-name="sourceTypeLabel"></record-type>

      <div class="mobile-image" v-if="!isDesktop && matchImage" @click="onImageClick">
        <img :data-src="matchImage" class="lazyload image with-shadow" />
      </div>

      <div class="meta-info">
        <div v-for="item in metaInfo">
          <span class="supplemental">{{ item.label }}: </span
          ><span
            :class="{concealed: isInfoValueConcealed(item.value)}"
            v-tooltip="getInfoValueTooltip(item.value)"
            @click="infoValueClick(item.value)"
            >{{ item.value }}</span
          >
        </div>
      </div>

      <div class="view-button-container" v-if="canReviewMatch">
        <router-link :to="matchRoute" v-if="dataVisible" class="view-button review-hint"
          ><view-icon :size="20" class="view-icon"></view-icon> Review Record Match
        </router-link>
        <a v-else class="view-button review-hint" @click="openSubscriptionWallModal"
          ><view-icon :size="20" class="view-icon"></view-icon> Review Record Match
        </a>
      </div>
    </div>

    <div class="desktop-image" v-if="isDesktop && matchImage" @click="onImageClick">
      <img :data-src="matchImage" class="lazyload image with-shadow" />
    </div>
  </div>
</template>

<script>
import RecordType from '@common/elements/icons/RecordType';
import WorkspacePremiumIcon from '@common/elements/icons/WorkspacePremiumIcon';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {getDateLocation, getFormattedResidenceText} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {getHintDetailRoute, getPersonRecordDetailRoute} from '@common/utils/utils.routes';
import {hasClassifiedData} from '@common/utils/utils.sources';
import ViewIcon from 'vue-material-design-icons/FolderSearch';

import {openHintsPaywallModal} from '@/components/common/subscriptions/utils';
import {getRecordTitle} from '@/components/common/tree/utils.personsCompare';

export default {
  props: {
    hint: Object,
    dataVisible: Boolean,
    dataClickable: {type: Boolean, default: true},
  },
  computed: {
    isDesktop() {
      const width = this.$store.getters.windowWidthState;
      return width > this.$breakpoints.mobile;
    },
    matchRoute() {
      if (this.canReviewMatch) {
        return getHintDetailRoute(this.hint.id);
      }
      return getPersonRecordDetailRoute(this.hint.match_person.code);
    },
    fullNameEn() {
      return getFullNameEn(this.hint.match_person.first_names, this.hint.match_person.surnames);
    },
    fullNameCn() {
      return getFullNameCn(this.hint.match_person.first_names, this.hint.match_person.surnames);
    },
    sourceTitle() {
      return getRecordTitle(this.fullNameEn, this.fullNameCn, this.hint.source);
    },
    sourceTypeLabel() {
      const country = this.hint.source.place && this.hint.source.place.display_text;
      const type = this.hint.source.type_display;
      return country && type ? `${country}, ${type}` : country || type;
    },
    birthData() {
      const location = this.hint.match_person.birth_location ? this.hint.match_person.birth_location.display_text : '';
      return getDateLocation(this.hint.match_person.birth_date, location);
    },
    deathData() {
      const location = this.hint.match_person.death_location ? this.hint.match_person.death_location.display_text : '';
      return getDateLocation(this.hint.match_person.death_date, location);
    },
    residenceData() {
      return this.hint.match_person.residence_location
        .map(residence => getFormattedResidenceText(residence))
        .join(', ');
    },
    matchImage() {
      return this.hint.image;
    },
    canReviewMatch() {
      if (!this.dataClickable) {
        return false;
      }
      return this.hint ? this.hint.status !== 'accepted' : false;
    },
    metaInfo() {
      return [
        {label: 'Name', value: `${this.fullNameEn} ${this.fullNameCn}`.trim()},
        {label: 'Gender', value: this.hint.match_person.gender_display},
        {label: 'Birth', value: this.birthData},
        {label: 'Death', value: this.deathData},
        {label: 'Ancestral Place', value: this.hint.match_person.ancestral_place_name},
        {label: 'Residence', value: this.residenceData},
      ].filter(item => !!item.value);
    },
  },
  methods: {
    openSubscriptionWallModal() {
      this.trackSubscriptionLinkClick();
      openHintsPaywallModal(this);
    },
    onImageClick() {
      if (!this.dataClickable) {
        return;
      }
      if (this.dataVisible) {
        return this.$router.push(this.matchRoute);
      }
      return this.openSubscriptionWallModal();
    },
    isInfoValueConcealed(value) {
      return !this.dataVisible && hasClassifiedData(value);
    },
    getInfoValueTooltip(value) {
      if (!this.dataClickable) {
        return;
      }
      return this.isInfoValueConcealed(value)
        ? {
            content: 'Grow your tree with RecordFinder™!\n<b>Click to start trial.</b>',
            theme: 'tooltip-info',
            placement: 'top',
          }
        : '';
    },
    infoValueClick(value) {
      if (!this.dataClickable) {
        return;
      }
      if (this.isInfoValueConcealed(value)) {
        this.openSubscriptionWallModal();
      }
    },
    trackSubscriptionLinkClick() {
      const routePageName = getRoutePageName(this.$route);
      AnalyticsMainHandler.trackHitPaywallAction('Review hint', 'hints-modal', routePageName, routePageName);
    },
  },
  components: {RecordType, ViewIcon, WorkspacePremiumIcon},
  name: 'MatchItem',
};
</script>

<style lang="scss" scoped>
@import '@common/pages/searches/styles/result-item.scss';
.match-item {
  padding: 20px 24px;
  margin: 0;
  border-top: 1px solid $mcr-grey-tan;

  .source-name {
    font-weight: bold;
  }

  .desktop-image {
    min-height: 150px;
  }

  .meta-info {
    margin-top: 20px;
    div {
      margin: 5px 0;
    }
  }

  .badge-container {
    display: flex;
    margin-bottom: 8px;
    column-gap: 5px;
  }

  .review-hint {
    font-weight: bold;
  }
}
</style>
