<template>
  <div @click="openSearchPanel" class="family-tree-search-button">
    <search-icon :size="24" /> <span class="label text-sm avenir-font-offset-1">{{ label }}</span>
  </div>
</template>

<script>
import SearchIcon from 'vue-material-design-icons/Magnify';

import FamilyTreeSearch from '@/components/modules/familyTree/tree/modals/quick/FamilyTreeSearch';
import {openQuickSidebar} from '@/components/modules/familyTree/tree/services.modals';

export default {
  computed: {
    label() {
      return this.isDesktop ? 'Search Tree' : 'Search';
    },
    isDesktop() {
      return this.$store.getters.windowWidthState >= this.$breakpoints.mobile;
    },
  },
  methods: {
    openSearchPanel() {
      const sidebarProps = {id: 'family-tree-search', time: new Date().getTime()};
      if (this.$store.getters.quickSidebarIdState === sidebarProps.id) {
        this.$store.commit('setQuickSidebarPropsState', sidebarProps);
      } else {
        openQuickSidebar(this, FamilyTreeSearch, sidebarProps, {}, {});
      }
    },
  },
  components: {
    SearchIcon,
  },
  name: 'familyTreeSearchButton',
};
</script>

<style lang="scss" scoped></style>
