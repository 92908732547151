<template>
  <div v-if="isNotFound">
    <slot name="page-not-found"></slot>
  </div>
  <div class="collection-details-page" v-else>
    <div class="limited_content">
      <mcr-loading-indicator v-if="loading" :loading="true"></mcr-loading-indicator>
      <div class="desktop-image" v-if="!loading && collectionDetailsState.image">
        <div class="image-wrapper" @click="showImageGallery">
          <img class="preview-image with-shadow" :src="collectionDetailsState.image" />
        </div>
      </div>
      <div class="details-content" v-if="!loading">
        <h5>{{ title }}</h5>

        <div v-if="userIsStaffState" class="admin-buttons">
          <mcr-button :href="adminEditLink" target="_blank" class="admin transparent">Open in admin</mcr-button>
        </div>
        <div class="mobile-image" v-if="collectionDetailsState.image">
          <div class="image-wrapper" @click="showImageGallery">
            <img class="preview-image with-shadow" :src="collectionDetailsState.image" />
          </div>
        </div>

        <summary-text
          v-if="collectionDetailsState.summary"
          :summary="collectionDetailsState.summary"
          :summary-preview="collectionDetailsState.summary_preview"
          class="sub-section"
        ></summary-text>

        <div class="section" v-if="collectionDetailsState.images_count">
          <b>Images available:</b><span> {{ collectionDetailsState.images_count.toLocaleString('en') }}</span>
        </div>
        <div v-if="collectionDetailsState.persons_count">
          <b>Total persons indexed:</b><span> {{ collectionDetailsState.persons_count.toLocaleString('en') }}</span>
        </div>

        <div class="section" v-if="collectionDetailsState.persons_count">
          <stand-out-block class="light">
            <div class="heading-6 title form-title">Search this collection</div>
            <div class="filter-group form-item">
              <div class="filter">
                <label class="input-label">First Name</label>
                <input
                  v-capitalize
                  :value="formData.first_name"
                  class="input"
                  @input="onInputChange('first_name', $event.target.value)"
                />
              </div>
              <div class="filter">
                <label class="input-label">Last Name</label>
                <input
                  v-capitalize
                  :value="formData.surname"
                  class="input"
                  @input="onInputChange('surname', $event.target.value)"
                />
              </div>
            </div>
            <div class="filter-group form-item">
              <place-input
                class="filter"
                label="Place"
                :value="formData.place || {}"
                :show-default-options="false"
                :only-ancestral-places="false"
                multiselect-classes="bordered"
                label-classes="input-label"
                @select="onInputChange('place', $event)"
              ></place-input>
              <div class="filter-group">
                <div class="filter small">
                  <label for="record_date" class="input-label">Year</label>
                  <div>
                    <input
                      id="record_date"
                      :value="formData.year"
                      placeholder="YYYY"
                      class="input record-year"
                      @input="onInputChange('year', $event.target.value)"
                    />
                  </div>
                </div>
                <mcr-button class="search-button" @click="onSearchClick">
                  <search-icon class="search-icon" :size="20"></search-icon>
                  <span class="bold">Search</span>
                </mcr-button>
              </div>
            </div>
            <div class="filter-group form-item search-all">
              <router-link :to="browseAllRoute">Browse all records in this collection</router-link>
            </div>
          </stand-out-block>
        </div>
        <div class="details-table section">
          <metadata-table :rows="detailsTableRows">
            <template v-slot:cell-name="{data}">{{ data.label }}</template>
            <template v-slot:cell-value="{data}">
              <div v-if="data.value">{{ data.value }}</div>
              <link-surname-page-family
                v-else-if="data.isClans"
                :clans="data.values"
                :show-family="false"
              ></link-surname-page-family>
              <div v-for="value in data.values" v-else-if="data.values">
                <router-link v-if="value.route" :to="value.route" :key="value.key">{{
                  value.display_text
                }}</router-link>
                <span v-else>{{ value }}</span>
              </div>
            </template>
          </metadata-table>
        </div>

        <div class="section">
          <stand-out-block-titled>
            <template v-slot:heading>
              <div class="heading-6 title">Sources Within Collection</div>
            </template>
            <template v-slot:content>
              <div class="source-items">
                <div class="source-item text-sm text-md-mobile" v-for="source in sourcesPage" :key="source.id">
                  <div class="source-name">
                    <router-link :to="getSourceRoute(source)" class="link">{{ source.full_title }}</router-link>
                  </div>
                  <div class="source-type">{{ source.type_display }}</div>
                </div>
              </div>
              <base-pagination
                :meta="sourcesMeta"
                :show-jump-to="false"
                class="text-sm text-md-mobile"
                @onSwitchPage="onSwitchPage"
              ></base-pagination>
            </template>
          </stand-out-block-titled>
        </div>
      </div>
      <images-gallery-overlay-container></images-gallery-overlay-container>
    </div>
  </div>
</template>

<script>
import BasePagination from '@common/elements/base-pagination';
import McrButton from '@common/elements/buttons/mcrButton';
import ImagesGalleryOverlayContainer from '@common/elements/gallery/imagesGalleryOverlayContainer';
import PlaceInput from '@common/elements/inputs/PlaceInput';
import MetadataTable from '@common/elements/layouts/MetadataTable';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import StandOutBlockTitled from '@common/elements/layouts/StandOutBlockTitled';
import SummaryText from '@common/elements/layouts/SummaryText';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {getGalleryImageData} from '@common/utils/utils';
import {getCollectionAdminUrl} from '@common/utils/utils.admin';
import {getCemeteryDetailRoute, getPlaceDetailRoute} from '@common/utils/utils.routes';
import SearchIcon from 'vue-material-design-icons/Magnify';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.title,
    };
  },
  created() {
    this.$store
      .dispatch('fetchCollectionDetailsAction', this.$route.params.id)
      .then(res => {
        this.sourcesMeta = {...this.sourcesMeta, total_count: res.sources.length};
      })
      .catch(error => {
        this.isNotFound = true;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: true,
      isNotFound: false,
      formData: {
        first_name: '',
        surname: '',
        place: {},
        year: '',
      },
      sourcesMeta: {offset: 0, limit: 15, total_count: 0},
    };
  },
  computed: {
    ...mapGetters(['collectionDetailsState', 'userIsStaffState']),
    title() {
      return this.collectionDetailsState.name;
    },
    timePeriod() {
      const {time_period_start, time_period_end} = this.collectionDetailsState;
      return time_period_start || time_period_end ? `${time_period_start || '...'}-${time_period_end || '...'}` : '';
    },
    browseAllRoute() {
      return {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS, collection_id: this.$route.params.id}};
    },
    adminEditLink() {
      return getCollectionAdminUrl(this.collectionDetailsState.id);
    },
    placeLinks() {
      return this.collectionDetailsState.places.map(place => {
        return {
          display_text: place.display_text || place.pinyin,
          key: place.id,
          route: getPlaceDetailRoute(place.id, place.pinyin),
        };
      });
    },
    ancestralPlaceLinks() {
      return this.collectionDetailsState.ancestral_places.map(place => {
        return {
          display_text: place.display_text || place.pinyin,
          key: place.id,
          route: getPlaceDetailRoute(place.id, place.pinyin),
        };
      });
    },
    cemeteriesLinks() {
      return this.collectionDetailsState.cemeteries.map(cemetery => {
        return {
          display_text: cemetery.name,
          key: cemetery.object_id,
          route: getCemeteryDetailRoute(cemetery.object_id, cemetery.name),
        };
      });
    },
    detailsTableRows() {
      return [
        {key: 'title', label: 'Title (pinyin)', value: this.collectionDetailsState.title_pinyin},
        {key: 'time_period', label: 'Time Period', value: this.timePeriod},
        {key: 'places', label: 'Places', values: this.placeLinks},
        {key: 'ancestral_places', label: 'Ancestral Places', values: this.ancestralPlaceLinks},
        {key: 'clans', isClans: true, label: 'Clans', values: this.collectionDetailsState.clans},
        {key: 'cemeteries', label: 'Cemeteries', values: this.cemeteriesLinks},
        {
          key: 'associations',
          label: 'Associations',
          values: this.collectionDetailsState.associations.map(a => a.name_en),
        },
      ].filter(obj => obj.value || (obj.values && obj.values.length));
    },
    sourcesPage() {
      const end = this.sourcesMeta.offset + this.sourcesMeta.limit;
      return this.collectionDetailsState.sources.slice(this.sourcesMeta.offset, end);
    },
  },
  methods: {
    getSourceRoute(source) {
      return {name: 'source-detail', params: {sourceId: source.id}};
    },
    onInputChange(field, value) {
      this.formData = {...this.formData, [field]: value};
    },
    onSearchClick() {
      const query = {
        tab: TAB_ID_INDEXED_RECORDS,
        collection_id: this.$route.params.id,
        first_name: this.formData.first_name,
        surname: this.formData.surname,
        auto_place_id: this.formData.place && this.formData.place.id ? this.formData.place.id : '',
        record_year: this.formData.year || '',
      };
      this.$router.push({name: 'search-all-records', query});
    },
    onSwitchPage(page) {
      this.sourcesMeta = {...this.sourcesMeta, offset: (page - 1) * this.sourcesMeta.limit};
    },
    showImageGallery() {
      const data = [getGalleryImageData(0, this.collectionDetailsState.image, '', {}, true)];
      this.$store.commit('setGalleryItemsState', data);
      this.$store.dispatch('displayGalleryAction', 0);
    },
  },
  components: {
    ImagesGalleryOverlayContainer,
    SummaryText,
    linkSurnamePageFamily,
    StandOutBlockTitled,
    PlaceInput,
    McrButton,
    SearchIcon,
    StandOutBlock,
    MetadataTable,
    BasePagination,
  },
  name: 'BaseCollectionDetailsPage',
};
</script>

<style lang="scss" scoped>
.collection-details-page {
  .limited_content {
    display: flex;
    flex-direction: row;
  }
  .details-content {
    flex-grow: 1;
    max-width: $reading-max-width;
    margin: auto;
  }
  h5 {
    margin-bottom: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid $divider-line-color;
  }
  .section {
    display: block;
    margin-top: 32px;
  }
  .desktop-image {
    margin-right: 48px;
    width: 40%;
    min-width: 280px;
    flex-shrink: 0;
    position: relative;
  }
  .mobile-image {
    display: none;
  }
  .image-wrapper {
    min-height: 250px;
    background-color: $background-alternate-color;
    max-width: fit-content;
    min-width: 100%;
    cursor: pointer;
  }
  .preview-image {
    display: block;
    min-height: 250px;
    margin: auto;
  }

  .title {
    color: $neutral-600;
  }
  .form-title {
    margin-bottom: 16px;
  }

  .source-items {
    display: flex;
    flex-direction: column;

    .source-item {
      padding: 12px 16px;
      display: flex;
      column-gap: 10px;
      justify-content: space-between;

      .source-type {
        flex-shrink: 0;
        color: $neutral-500;
      }
    }
  }

  .pagination {
    margin-top: 12px;
  }

  .filter-group {
    display: flex;
    column-gap: 16px;
    flex-grow: 1;
    > * {
      width: 100%;
    }
    .filter {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
    }
    .filter.small {
      width: fit-content;
      flex-grow: 0;
      flex-shrink: 0;

      input {
        width: 100px;
      }
    }
  }
  .search-button {
    align-self: center;
    height: 44px;
  }

  .admin-buttons {
    display: flex;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    .desktop-image {
      display: none;
    }
    .mobile-image {
      display: block;
      margin: 30px 0;
    }
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    .source-items {
      .source-item {
        flex-direction: column;
      }
    }
    .filter-group {
      flex-direction: column;
      .filter {
        max-width: 100%;
        width: 100%;
        margin-bottom: 16px;
      }
    }
    .search-button {
      align-self: stretch;
      width: 100%;
    }
    .search-all {
      margin-top: 16px;
    }
  }
}
</style>
