<template>
  <stand-out-block class="indexed-record-result-item result-item" :class="{'is-row': isDesktop}" :id="recordItemId">
    <div class="content">
      <h4 class="title">
        <router-link :to="toRoute" class="details-link" @click.native="trackSubscriptionLinkClick('person name')">
          <span>{{ displayName }}</span>
        </router-link>
      </h4>

      <record-type :type="item.source.mention_type_display" :type-name="recordTypeLabel"></record-type>

      <div class="supplemental alternative" v-if="aliasesMatches" v-html="aliasesMatches"></div>

      <div class="mobile-image" v-if="!isDesktop && imageSrc">
        <img :data-src="imageSrc" class="lazyload image with-shadow" @click="onImageClick" />
      </div>

      <stand-out-block v-if="isDataProtected" class="warning text-md">
        <div class="icon-container">
          <privacy-icon :size="20"></privacy-icon>
        </div>
        <div>Record data hidden because it may refer to a living person</div>
      </stand-out-block>
      <div class="meta-info" v-else>
        <div v-if="userIsStaffState" class="status supplemental">
          Status: <span :style="statusStyle">{{ item.status_display }}</span>
        </div>
        <div v-if="userIsStaffState && item.person.is_potentially_living" class="status supplemental">
          Is potentially living: True
        </div>
        <div v-if="userIsStaffState && item.match_rank" class="supplemental">
          Rank: <span>{{ item.match_rank }}</span>
        </div>

        <div v-for="item in metaInfo">
          <span class="supplemental">{{ item.label }}: </span
          ><span
            :class="{concealed: isInfoValueConcealed(item.value)}"
            v-tooltip="getInfoValueTooltip(item.value)"
            v-html="item.value"
            @click="infoValueClick(item.value)"
          ></span>
        </div>
      </div>
      <div class="view-button-container">
        <router-link :to="toRoute" class="view-button" @click.native="trackSubscriptionLinkClick('View Record')"
          ><view-icon :size="20" class="view-icon"></view-icon> View Record
        </router-link>
        <router-link :to="collectionDetailsRoute" class="view-button" v-if="collectionDetailsRoute"
          ><view-icon :size="20" class="view-icon"></view-icon> View Collection Details</router-link
        >
        <router-link :to="sourceDetails" class="view-button" v-else
          ><view-icon :size="20" class="view-icon"></view-icon> View Source Details</router-link
        >
      </div>
    </div>

    <div class="desktop-image" v-if="isDesktop && imageSrc">
      <img :data-src="imageSrc" class="lazyload image with-shadow" @click="onImageClick" />
    </div>
  </stand-out-block>
</template>

<script>
import RecordType from '@common/elements/icons/RecordType';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import consts from '@common/utils/consts';
import {publishStatuses} from '@common/utils/consts.source';
import {
  formatApproximateDate,
  getDateLocationHighlighted,
  getFormattedResidenceText,
  getHighlightString,
  sourceImageFastThumbnailLink,
  truncateText,
} from '@common/utils/utils';
import {getFullNameCn, getFullNameEn, getPersonAliasMatchHighlighted} from '@common/utils/utils.names';
import {getPersonRecordDetailRoute, getSubscriptionWallRoute} from '@common/utils/utils.routes';
import {hasClassifiedData} from '@common/utils/utils.sources';
import ViewIcon from 'vue-material-design-icons/FolderMoveOutline';
import PrivacyIcon from 'vue-material-design-icons/ShieldAlertOutline';
import {mapGetters} from 'vuex';

export default {
  props: {
    item: Object,
    options: Object,
  },
  computed: {
    ...mapGetters(['userIsStaffState', 'userIsLoggedInState']),
    toRoute() {
      const personRoute = getPersonRecordDetailRoute(this.item.person.code, this.fullNameEn);
      if (!this.userIsLoggedInState && this.item.source.is_publicly_available) {
        return {name: 'register', query: {redirect: this.$router.resolve(personRoute).href}};
      }
      if (!this.item.user_has_full_access) {
        return getSubscriptionWallRoute(
          this.$router.resolve(personRoute).href,
          this.fullNameEn || this.fullNameCn || ''
        );
      }
      return personRoute;
    },
    sourceDetails() {
      return {name: 'source-detail', params: {sourceId: this.item.source.id}};
    },
    collectionDetailsRoute() {
      if (this.item.source.collection_ids && this.item.source.collection_ids[0]) {
        return {name: 'collection-detail', params: {id: this.item.source.collection_ids[0]}};
      }
    },
    fullNameEn() {
      return getFullNameEn(this.item.person.first_names, this.item.person.surnames);
    },
    fullNameCn() {
      return getFullNameCn(this.item.person.first_names, this.item.person.surnames);
    },
    fullName() {
      return [this.fullNameEn, this.fullNameCn].join(' ').trim();
    },
    businessName() {
      const personInfo = this.item.person.info || {};
      if (personInfo.business) {
        const businessName = this.isInfoValueConcealed(personInfo.business) ? '' : personInfo.business;
        return `${businessName} (Business)`.trim();
      }
      return '';
    },
    displayName() {
      return this.fullName || this.businessName || 'Unknown';
    },
    statusStyle() {
      return this.item.status === publishStatuses.ACTIVE ? {color: 'green'} : {color: 'grey'};
    },
    recordTypeLabel() {
      const type =
        this.item.source.mention_type_display && this.item.source.mention_type_display !== 'none'
          ? this.item.source.mention_type_display
          : '';
      const country = this.item.source.country && this.item.source.country !== 'none' ? this.item.source.country : '';
      const place =
        this.item.source.place_name && this.item.source.place_name !== 'none' ? this.item.source.place_name : '';
      const placeName = country && place && country !== place ? `${place}, ${country}` : country || place;
      return type && placeName ? `${placeName}, ${type}` : type || placeName;
    },
    birthData() {
      const h = this.item.person.highlight;
      const highlightLocation = h && (h.includes('birth_location') || h.includes('birth_location_ids'));
      return this.getDateLocation(this.item.person.birth_date, this.item.person.birth_location, highlightLocation);
    },
    deathData() {
      const h = this.item.person.highlight;
      const highlightLocation = h && (h.includes('death_location') || h.includes('death_location_ids'));
      return this.getDateLocation(this.item.person.death_date, this.item.person.death_location, highlightLocation);
    },
    sourceData() {
      const title = truncateText(this.item.source.title_en || this.item.source.title_ch || 'Unknown', 98);
      const year = this.item.source.publication_year;
      return year ? `${title}, ${year}` : title;
    },
    collectionData() {
      return this.item.source.collection_names ? this.item.source.collection_names.join(', ') : '';
    },
    residenceData() {
      return this.item.person.residence_list.map(residence => getFormattedResidenceText(residence, true)).join(', ');
    },
    aliasesMatches() {
      if (this.$route.query.surname || this.$route.query.first_name) {
        return getPersonAliasMatchHighlighted(
          this.item.person.first_names,
          this.item.person.surnames,
          this.$route.query.first_name,
          this.$route.query.surname,
          [this.fullName, this.businessName]
        );
      }
    },
    isDesktop() {
      const width = this.$store.getters.windowWidthState;
      return width >= 1100 || (width >= this.$breakpoints.phablet && width <= this.$breakpoints.tablet);
    },
    imageSrc() {
      if (this.item.thumbnail_token) {
        return sourceImageFastThumbnailLink(this.item.thumbnail_token, 400);
      }
    },
    metaInfo() {
      const person_info = this.item.person.info || {};
      const gender = this.item.person.gender;
      const genderDisplay = gender === 'none' ? '' : consts.GENDERS[gender] || gender;
      const res = [
        {label: 'Gender', value: genderDisplay},
        {label: 'Born', value: this.birthData},
        {label: 'Died', value: this.deathData},
        {label: 'Business Name', value: person_info.business},
        {label: 'Address', value: person_info.business_address},
        {label: 'Residence', value: this.residenceData},
      ];
      for (let fact of this.item.person.facts) {
        if (fact.fact_type.is_event) {
          res.push({label: fact.fact_type.label, value: this.getFactDisplayValue(fact)});
        }
      }

      const surnames = this.item.source.clan_names.join(', ');
      const place =
        this.item.source.ancestral_place_names && this.item.source.ancestral_place_names.length
          ? this.item.source.ancestral_place_names.join(', ')
          : '';
      const value = surnames || place ? `${surnames || 'Unknown'} family from ${place || 'Unknown'}` : '';
      res.push({label: 'Clan', value: value});
      if (this.collectionData) {
        res.push({label: 'Collection', value: this.collectionData});
      } else {
        res.push({label: 'Source', value: this.sourceData});
      }
      return res.filter(item => !!item.value);
    },
    isDataProtected() {
      return this.item.person.is_potentially_living && !this.userIsStaffState;
    },
    recordItemId() {
      return `record-${this.item.person.code}`;
    },
  },
  methods: {
    getDateLocation(date, location, highlightLocation = false) {
      const highlightDate = date && date.highlight && date.highlight.includes('years');
      return getDateLocationHighlighted(date, location, highlightDate, highlightLocation);
    },
    trackSubscriptionLinkClick() {
      if (!this.item.user_has_full_access) {
        const fullPath = this.$router.resolve(this.toRoute).href;
        AnalyticsMainHandler.trackHitPaywallAction(
          'View mention detail',
          this.toRoute.name,
          fullPath,
          getRoutePageName(this.$route)
        );
      }
    },
    onImageClick() {
      this.trackSubscriptionLinkClick();
      this.$router.push(this.toRoute);
    },
    isInfoValueConcealed(value) {
      return !this.item.user_has_full_access && hasClassifiedData(value);
    },
    getFactDisplayValue(fact) {
      let {value, start_date, end_date, place_display_text, highlight} = fact;
      value = highlight && highlight.includes('value') ? getHighlightString(value) : value;
      const shouldHighlightYear = highlight && highlight.includes('years');
      const shouldHighlightPlace =
        highlight && (highlight.includes('place_display_text') || highlight.includes('place_ids'));
      const placeDisplay = shouldHighlightPlace ? getHighlightString(place_display_text) : place_display_text;
      start_date = start_date ? formatApproximateDate(start_date, shouldHighlightYear) : '';
      end_date = end_date ? formatApproximateDate(end_date, shouldHighlightYear) : '';

      let date = start_date && end_date ? `${start_date} - ${end_date}` : start_date || end_date;
      const datePlace = date && placeDisplay ? `${date} in ${placeDisplay}` : date || placeDisplay;

      return value && datePlace ? `${value}, ${datePlace}` : datePlace || value;
    },
    getInfoValueTooltip(value) {
      return this.isInfoValueConcealed(value)
        ? {
            content: 'Discover this and much more!\n<b>Click to subscribe now.</b>',
            placement: 'top-start',
            theme: 'tooltip-info',
          }
        : '';
    },
    infoValueClick(value) {
      if (this.isInfoValueConcealed(value)) {
        this.trackSubscriptionLinkClick('concealed value');
        this.$router.push(this.toRoute);
      }
    },
  },
  components: {StandOutBlock, RecordType, ViewIcon, PrivacyIcon},
  name: 'IndexedRecordItem',
};
</script>

<style lang="scss" scoped>
@import 'styles/result-item';

.indexed-record-result-item {
  .alternative {
    margin: 20px 0;
  }
  .meta-info {
    margin-top: 20px;

    > * {
      word-break: break-word;
    }
  }

  .warning {
    box-shadow: $slight-shadow-bottom;
    background: white;
    color: $neutral-600;
    padding: 12px 16px;
    display: flex;
    gap: 12px;
    align-items: center;
    .icon-container {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary-50;
      color: $primary-400;
      border-radius: 100%;
      flex-shrink: 0;
    }
  }
}
</style>
