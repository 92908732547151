<template>
  <div class="ocr-area-confirm-modal with-sticky-buttons">
    <div class="heading">
      <div class="text-lg bold">Selected Area OCR</div>
      <close-icon class="close-icon" @click="closeModal"></close-icon>
    </div>
    <div class="body">
      <div class="language-block">
        <ocr-type-select :value="ocrType" :options="langOptionsAsArray" @select="onOcrTypeSelect"></ocr-type-select>
        <div class="translation-block" v-if="userCanTranslate">
          <div class="text-md">Show translation</div>
          <switch-toggle
            class="small"
            :value="showTranslation"
            @toggle="toggleShowTranslation"
            v-tooltip="switchToggleTooltip"
          ></switch-toggle>
        </div>
      </div>

      <div class="result-block">
        <div class="translated-text loading supplemental" v-if="translatedTextLoading">Translating...</div>
        <div class="translated-text text-xl" v-if="translatedText && showTranslation">{{ translatedText }}</div>
        <div class="ocr-text text-xl">{{ ocrText }}</div>
        <div class="error">{{ errorText }}</div>
        <div class="loading supplemental" v-if="ocrLoading">Processing image snippet...</div>

        <div v-if="base64Image" class="image-snippet">
          <img :src="base64Image" />
        </div>
        <div class="image-container image-snippet" :style="imageContainerStyle" v-else-if="!rotate">
          <img :src="url" :width="imageWidth" :height="imageHeight" :style="imagePreviewStyle" />
        </div>
      </div>
    </div>

    <translate-subscription-banner v-if="!userCanTranslate" @click.native="goToSubscriptionPlans" />
    <div class="action-buttons right-align">
      <mcr-button class="white" @click="closeModal">{{ closeLabel }}</mcr-button>
      <mcr-button class="parser-button" v-if="cacheKey" @click="openParser">
        <key-cup>T</key-cup>
        Open in parser
      </mcr-button>
      <mcr-button @click="runOcrSelection" :loading="ocrLoading">{{ okLabel }}</mcr-button>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import KeyCup from '@common/elements/icons/KeyCup';
import SwitchToggle from '@common/elements/inputs/SwitchToggle';
import OcrTypeSelect from '@common/elements/layouts/area-selection/OcrTypeSelect.vue';
import TranslateSubscriptionBanner from '@common/elements/layouts/area-selection/TranslateSubscriptionBanner';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {isChineseText} from '@common/utils/utils';
import {getOcrParserAdminUrl} from '@common/utils/utils.admin';
import {SUBSCRIBE_OPTION, gtLangOptionsAsArray} from '@common/utils/utils.ocr';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';
import CloseIcon from 'vue-material-design-icons/Close';
import {mapGetters} from 'vuex';

export default {
  props: {
    url: String,
    rotate: {type: Number, default: 0},
    imageWidth: Number,
    imageHeight: Number,
    x1: Number,
    y1: Number,
    x2: Number,
    y2: Number,
    renderScale: Number,
    closeLabel: {type: String, default: 'Close'},
  },
  created() {
    document.addEventListener('keydown', this.onShortcutPress);
    if (!this.userIsSubscribedState && !this.$store.getters.subscriptionPlansState.length) {
      this.$store.dispatch('fetchSubscriptionPlansAction');
      this.$store.dispatch('fetchSubscriptionsAction');
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.onShortcutPress);
  },
  data() {
    return {
      ocrText: '',
      translatedText: '',
      translatedTextLoading: false,
      errorText: '',
      base64Image: null,
      cacheKey: '',
      ocrLoading: false,
      ocrType: null,
      showTranslation: false,
    };
  },
  mounted() {
    this.setInitialOcrType();
    this.runOcrSelection();
  },
  computed: {
    ...mapGetters(['userIsStaffState', 'userIsSubscribedState', 'isTrialEligibleState', 'standardYearlyPlanState']),
    langOptionsAsArray() {
      const planName = this.standardYearlyPlanState ? this.standardYearlyPlanState.display_text : 'Detective';
      return gtLangOptionsAsArray(
        this.userIsStaffState,
        this.userIsSubscribedState,
        this.isTrialEligibleState,
        planName
      );
    },
    okLabel() {
      return this.ocrText ? 'Re-run OCR' : 'Run OCR';
    },
    left() {
      return Math.min(this.x1, this.x2);
    },
    top() {
      return Math.min(this.y1, this.y2);
    },
    snippetWidth() {
      return Math.abs(this.x1 - this.x2);
    },
    snippetHeight() {
      return Math.abs(this.y1 - this.y2);
    },
    maxImageSize() {
      const windowWidth = this.$store.getters.windowWidthState;
      if (windowWidth > 800) {
        return 600;
      }
      return windowWidth - 50;
    },
    scale() {
      if (this.snippetWidth > this.maxImageSize) {
        return this.maxImageSize / this.snippetWidth;
      }
      return 1;
    },
    imageContainerStyle() {
      let newWidth = this.snippetWidth * this.scale;
      let newHeight = this.snippetHeight * this.scale;

      return {
        width: newWidth + 'px',
        height: newHeight + 'px',
        overflow: 'hidden',
      };
    },
    imagePreviewStyle() {
      return {
        position: 'relative',
        top: `-${this.top * this.scale}px`,
        left: `-${this.left * this.scale}px`,
        maxWidth: this.imageWidth * this.scale + 'px',
        overflow: 'hidden',
      };
    },
    switchToggleEnabled() {
      return this.ocrText && isChineseText(this.ocrText);
    },
    switchToggleTooltip() {
      if (!this.switchToggleEnabled) {
        return {
          content: 'Translation is available for Chinese extracted text only',
          container: false,
          triggers: ['click', 'hover'],
        };
      }
    },
    userCanTranslate() {
      return this.userIsStaffState || this.userIsSubscribedState;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    setInitialOcrType() {
      const paramId = this.$route.params.sourceId || this.$route.params.zupuId;
      const savedLang = paramId ? this.$store.getters.ocrLangBySourceIdState[paramId] : '';
      const source = this.$store.getters.sourceDetailsState;

      const defaultLang = paramId && source ? (source.zupu_id ? 'cn' : 'en') : 'cn';
      const premiumAccess = this.$store.getters.userIsSubscribedState || this.$store.getters.userIsStaffState;
      const defaultOCRforUser = premiumAccess ? 'baidu_ocr' : defaultLang;
      const lang = savedLang || defaultOCRforUser;
      this.ocrType = this.langOptionsAsArray.find(item => item.id === lang) || this.langOptionsAsArray[0];
    },
    openParser() {
      window
        .open(
          getOcrParserAdminUrl(
            this.$route.params.sourceId || '',
            this.$route.params.zupuId || '',
            this.$route.query.page || 1,
            this.$route.query.page_id || '',
            this.cacheKey
          ),
          '_blank'
        )
        .focus();
    },
    runOcrSelection() {
      this.ocrLoading = true;
      this.ocrText = '';
      this.base64Image = null;
      this.translatedText = '';
      this.showTranslation = false;
      this.errorText = '';

      const params = {
        url: this.url,
        lang: this.ocrType.id,
        rotate: this.rotate,
        x1: Math.floor(Math.min(this.x1, this.x2) * this.renderScale) - 4,
        x2: Math.ceil(Math.max(this.x1, this.x2) * this.renderScale) + 4,
        y1: Math.floor(Math.min(this.y1, this.y2) * this.renderScale) - 4,
        y2: Math.ceil(Math.max(this.y1, this.y2) * this.renderScale) + 4,
      };
      this.$store
        .dispatch('runOcrForImageArea', params)
        .then(params => {
          this.ocrLoading = false;
          const fullText = params.full_text ? params.full_text.trim() : '';
          this.ocrText = fullText || 'Text not detected.';
          this.base64Image = params.image_data ? 'data:image/jpeg;base64,' + params.image_data : null;
          this.cacheKey = params.cache_key;
          AnalyticsMainHandler.trackSelectAreaOcrResultsEvent(
            this.ocrType.id,
            Boolean(fullText),
            getRoutePageName(this.$route)
          );
        })
        .catch(err => {
          this.ocrLoading = false;
          const errorText =
            err.response && err.response.data && err.response.data.detail ? err.response.data.detail[0].msg : err;
          this.errorText = 'Error during OCR: ' + errorText;
        });
    },
    onOcrTypeSelect(value) {
      if (value.id.startsWith(SUBSCRIBE_OPTION)) {
        this.closeModal();
        this.$router.push(getSubscriptionWallRoute(this.$route.fullPath, null));
        return;
      }
      this.ocrType = value;
      this.$store.commit('mutateOcrLangBySourceIdState', {
        id: this.$route.params.sourceId || this.$route.params.zupuId,
        lang: value.id,
      });
      this.runOcrSelection();
    },
    onShortcutPress(event) {
      if (event.key === 't' && this.cacheKey) {
        this.openParser();
      }
    },
    toggleShowTranslation() {
      if (this.switchToggleEnabled) {
        this.showTranslation = !this.showTranslation;
        if (this.showTranslation && !this.translatedText) {
          this.translatedTextLoading = true;
          this.$store
            .dispatch('translateTextAction', {text: this.ocrText})
            .then(response => {
              this.translatedText = response.translated_text;
            })
            .finally(() => {
              this.translatedTextLoading = false;
            });
        }
      }
    },
    goToSubscriptionPlans() {
      this.closeModal();
      const route = getSubscriptionWallRoute(this.$route.fullPath, null, false);
      this.$router.push(route);
    },
  },
  components: {OcrTypeSelect, TranslateSubscriptionBanner, SwitchToggle, McrButton, KeyCup, CloseIcon},
  name: 'OcrAreaConfirmModal',
};
</script>

<style lang="scss" scoped>
.ocr-area-confirm-modal {
  min-width: 800px;
  .language-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    column-gap: 16px;
    border-bottom: 1px solid $neutral-200;
    > * {
      width: 100%;
    }
    .translation-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $neutral-800;
      column-gap: 16px;
    }
  }
  .result-block {
    padding: 24px;
    .translated-text {
      padding: 20px;
      font-style: italic;
      background: $neutral-100;
      margin-bottom: 24px;
    }
    .image-snippet {
      margin-top: 24px;
    }
  }
  .ocr-text,
  .translated-text {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .parser-button {
    .keycup {
      margin-right: 8px;
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    min-width: auto;
    .language-block {
      flex-direction: column;
      padding: 24px 16px;
      row-gap: 24px;
      .translation-block {
        justify-content: space-between;
      }
    }
    .result-block {
      padding: 24px 16px;
    }
  }
  @media only screen and (max-width: $breakpoint-phablet) {
    .body {
      padding-bottom: 130px;
    }
    .subscription-banner {
      position: fixed;
      bottom: 77px;
      left: 0;
      right: 0;
      margin: 0;
      border-radius: 0;
      padding: 12px 16px;
      background-image: linear-gradient($neutral-100, $neutral-100);
      border-top: 1px solid $neutral-200;
      &::v-deep .heading-3 {
        color: $text-color;
        margin-bottom: 12px;
      }
      &::v-deep .link-text {
        color: $primary-600;
      }
    }
    .parser-button {
      display: none;
    }
  }
}
</style>
